import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string | null | undefined, limit: number): string {
    if (!value || limit < 1) return value ?? '';

    const isPlainText = !/<[a-z][\s\S]*>/i.test(value);
    if (isPlainText) {
      if (value.length <= limit) return value;
      const truncatedText = value.substring(0, limit);
      const lastSpaceIndex = truncatedText.lastIndexOf(' ');
      return (lastSpaceIndex > -1 ? truncatedText.substring(0, lastSpaceIndex) : truncatedText) + '...';
    }

    let truncatedHtml = '';
    let charCount = 0;
    const openTags: string[] = [];

    function processNode(node: ChildNode): boolean {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent || '';
        if (charCount + text.length > limit) {
          const remaining = limit - charCount;
          const truncatedText = text.substring(0, remaining);
          const lastSpaceIndex = truncatedText.lastIndexOf(' ');
          truncatedHtml += lastSpaceIndex > -1 ? truncatedText.substring(0, lastSpaceIndex) : truncatedText;
          charCount = limit;
          return false;
        }
        truncatedHtml += text;
        charCount += text.length;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as HTMLElement;
        const tagName = element.tagName.toLowerCase();
        truncatedHtml += `<${tagName}${element.attributes.length ? ' ' : ''}${Array.from(element.attributes)
          .map(attr => `${attr.name}="${attr.value}"`)
          .join(' ')}>`;
        openTags.push(tagName);
        for (const child of Array.from(node.childNodes)) {
          if (!processNode(child)) return false;
        }
        truncatedHtml += `</${tagName}>`;
        openTags.pop();
      }
      return charCount < limit;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');
    for (const node of Array.from(doc.body.childNodes)) {
      if (!processNode(node)) break;
    }

    while (openTags.length > 0) {
      truncatedHtml += `</${openTags.pop()}>`;
    }

    return truncatedHtml + (charCount >= limit ? '...' : '');
  }
}
