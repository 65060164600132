import { Apollo } from 'apollo-angular';
import { concatMap, map, Observable, of } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import {
  DceDownloadResponse,
  DceResponse,
  DocsMethods,
  File,
  GetDocsPayload,
  HighlightResult,
  ToasterService
} from '@ggp/generic/shared/services';
import { TranslateService } from '@ngx-translate/core';

import { DocsQuery } from '../query/docsQuery';
import {
  downloadFileQueryGQL,
  getFileContentQueryGQL,
  getFilesQueryGQL,
  searchByKeywordsDocumentsQueryGQL
} from '../schema/docs-queries.schema';

@Injectable({
  providedIn: 'root',
})
export class EbpDocsService implements DocsMethods {
  readonly #apollo = inject(Apollo);
  readonly #toasterService = inject(ToasterService);
  readonly #translate = inject(TranslateService);

  getDocs({ documentId, projectId, keywords, language }: GetDocsPayload): Observable<DceResponse> {
    return this.getFiles(projectId, documentId).pipe(
      map(allDocs => allDocs),
      concatMap(allDocs => {
        const response: DceResponse = {
          allDocs: { files: allDocs },
        };
        if (allDocs && keywords.length > 0) {
          return this.getFilesByKeywords(projectId, keywords, language).pipe(
            map(withKeywords => {
              response.withKeywords = withKeywords;
              return response;
            }),
          );
        } else return of(response);
      }),
    );
  }

  private getFiles(projectId: string, documentId: string): Observable<File[]> {
    return this.#apollo
      .query<DocsQuery>({
        query: getFilesQueryGQL,
        variables: {
          projectId,
          documentId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data, errors }) => {
          if (errors) {
            this.#toasterService.openToaster(this.#translate.instant('ERRORS.SERVER.MESSAGE'), 'warning', this.#translate.instant('ERRORS.SERVER.TITLE'));
          }
          return data?.getFilesQuery;
        }),
      );
  }

  private getFilesByKeywords(projectId: string, keywords: string[], language: string) {
    return this.#apollo
      .query<DocsQuery>({
        query: searchByKeywordsDocumentsQueryGQL,
        variables: {
          projectId,
          keywords,
          language,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data.searchByKeywordsDocumentsQuery.map(file => {
            file.highlightedResults = this.highlightProcessContent(file.text);
            return file;
          });
        }),
      );
  }

  getFileContent(fileId: string, documentId: string): Observable<string> {
    return this.#apollo
      .query<DocsQuery>({
        query: getFileContentQueryGQL,
        variables: {
          fileId,
          documentId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data?.getFileContentQuery;
        }),
      );
  }

  downloadFile(documentId: string, filesId: string[]): Observable<DceDownloadResponse> {
    return this.#apollo
      .query<DocsQuery>({
        query: downloadFileQueryGQL,
        variables: {
          documentId,
          filesId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data?.downloadFileQuery;
        }),
      );
  }

  private highlightProcessContent(content: string): HighlightResult[] {
    const results: HighlightResult[] = [];
    const highlightWords = this.getHighlightedWords(content);
    let highlightedTextsIndexed = this.indexHighlightedWords(highlightWords);

    const sectionsBy15Words = this.splitTextIntoSections(content);
    sectionsBy15Words.forEach(section => {
      const regex = /<span class="highlight-keyword">(.*?)<\/span>/g;
      const match = regex.exec(section);

      if (match !== null) {
        const fullTextHighlightedObject = this.addHighlightIds(highlightedTextsIndexed, section);
        const fullTextWithIds = fullTextHighlightedObject?.span;
        const snippetWithIds = '...' + this.splitTextIntoSections(fullTextWithIds)[0];
        highlightedTextsIndexed = fullTextHighlightedObject?.highlightedTextsIndexed;

        results.push({
          snippet: snippetWithIds,
          fullSnippet: fullTextWithIds,
          showMore: true,
        });
      }
    });
    return results;
  }

  private getHighlightedWords(content: string) {
    if (!content) {
      return [];
    }

    const regex = /<span class="highlight-keyword">(.*?)<\/span>/g;
    const matches = [];
    let match;

    while ((match = regex.exec(content)) !== null) {
      matches.push(match[1]);
    }

    return matches;
  }

  private splitTextIntoSections(text: string, wordsPerSection = 15): string[] {
    const regex = /\s+(?![^<]*>)/g;

    const words = text.split(regex);
    const sections: string[] = [];
    let currentSection: string[] = [];

    words.forEach(word => {
      if (currentSection.length < wordsPerSection) {
        currentSection.push(word);
      } else {
        sections.push(currentSection.join(' '));
        currentSection = [word];
      }
    });

    if (currentSection.length > 0) {
      sections.push(currentSection.join(' '));
    }

    return sections;
  }

  private addHighlightIds(highlightedTextsIndexed: any, section: string): { span: string; highlightedTextsIndexed: any } {
    for (const word in highlightedTextsIndexed) {
      const regex = new RegExp(`<span class="highlight-keyword">(${word})</span>`, 'gi');
      section = section.replace(regex, (match, capturedText) => {
        let index = -1;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < highlightedTextsIndexed[word].length; i++) {
          index = highlightedTextsIndexed[word][i];
          if (index > -1) {
            highlightedTextsIndexed[word].splice(i, 1);
            break;
          }
        }
        const id = `highlight-${capturedText}-${index}`;

        return `<span class="highlight-keyword" id="${id}">${capturedText}</span>`;
      });
    }

    return { span: section, highlightedTextsIndexed: highlightedTextsIndexed };
  }

  private indexHighlightedWords(highlightWords: string[]): { [word: string]: number[] } {
    const highlightedTextsIndexed: { [word: string]: number[] } = {};
    highlightWords
      .sort((a, b) => a.toLowerCase().localeCompare(b))
      .forEach(word => {
        const lowerCaseWord = word.toLowerCase();
        if (!highlightedTextsIndexed[lowerCaseWord]) {
          highlightedTextsIndexed[lowerCaseWord] = [];
        }
        highlightedTextsIndexed[lowerCaseWord].push(highlightedTextsIndexed[lowerCaseWord].length + 1);
      });

    return highlightedTextsIndexed;
  }
}
