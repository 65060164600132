import { Pipe, PipeTransform } from '@angular/core';

const UNICODE_NORMALIZATION_FORM = 'NFD';
const DIACRITICS_REGEX = /[\u0300-\u036f]/g;

@Pipe({
  name: 'normalizeText',
  standalone: true,
})
export class NormalizeTextPipe implements PipeTransform {
  transform(value: string, regex?: string): string {
    if (!value) return '';

    let normalized = value.toLowerCase().normalize(UNICODE_NORMALIZATION_FORM).replace(DIACRITICS_REGEX, '');

    if (regex) {
      normalized = normalized.replace(new RegExp(regex, 'g'), '');
    }

    return normalized.trim();
  }
}
