export enum OrganisationRole {
  PROJECT_OWNER = 'PROJECT_OWNER',
  ADMINISTRATION = 'ADMINISTRATION',
  ARCHITECT = 'ARCHITECT',
  CONTRACTOR = 'CONTRACTOR',
  OTHER = 'OTHER',
  USER = 'USER',
  GLASS = 'GLASS',
  BET_STRUCTURES = 'BET_STRUCTURES',
  EXECUTION_CONTRACTOR = 'EXECUTION_CONTRACTOR',
  OPERATION_ARCHITECT = 'OPERATION_ARCHITECT',
  DESIGN_CONTRACTOR = 'DESIGN_CONTRACTOR',
  INTERIOR_ARCHITECT = 'INTERIOR_ARCHITECT',
  URBAN_PLANNER = 'URBAN_PLANNER',
  DESIGN_ARCHITECT = 'DESIGN_ARCHITECT',
  BET_ACOUSTICS = 'BET_ACOUSTICS',
  BET_OTHERS = 'BET_OTHERS',
  PLAN_TRACER = 'PLAN_TRACER',
  INVESTOR = 'INVESTOR',
  REAL_ESTATE = 'REAL_ESTATE',
  CONSULTING_ENGINEER = 'CONSULTING_ENGINEER',
  CHIEF_ARCHITECT = 'CHIEF_ARCHITECT',
  FLOOR_COVERING = 'FLOOR_COVERING',
  BET_ELEVATORS = 'BET_ELEVATORS',
  ROOF_FACADE = 'ROOF_FACADE',
  PUBLIC_TECHNIC = 'PUBLIC_TECHNIC',
  OPERATION_LEADER = 'OPERATION_LEADER',
  PROGRAMMER = 'PROGRAMMER',
  LANDSCAPE_ARCHITECT = 'LANDSCAPE_ARCHITECT',
  SUPPLIER = 'SUPPLIER',
  ELECTRICIAN = 'ELECTRICIAN',
  DOORS = 'DOORS',
  PROMOTER = 'PROMOTER',
  METAL_WORK = 'METAL_WORK',
  WOODWORK = 'WOODWORK',
  FOUNDATION = 'FOUNDATION',
  HEATING = 'HEATING',
  FIRE_SAFETY = 'FIRE_SAFETY',
  STRUCTURE_ENGINEER = 'STRUCTURE_ENGINEER',
  PAINTER = 'PAINTER',
  CONSULTANT = 'CONSULTANT',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  ROAD_NETWORK = 'ROAD_NETWORK',
  DEMOLITION = 'DEMOLITION',
  OWNER = 'OWNER',
  PLASTERER = 'PLASTERER',
  INTERIOR_WOODWORK = 'INTERIOR_WOODWORK',
  MASONRY = 'MASONRY',
  SURVEYOR = 'SURVEYOR',
  ASBESTOS_REMOVAL = 'ASBESTOS_REMOVAL',
  BET_STABILITY = 'BET_STABILITY',
  ENVIRONMENTAL_CONSULTANT = 'ENVIRONMENTAL_CONSULTANT',
  ENERGY_CONSULTANT = 'ENERGY_CONSULTANT',
  HVAC_CONSULTANT = 'HVAC_CONSULTANT',
  ELEVATOR_INSTALLER = 'ELEVATOR_INSTALLER'
}
