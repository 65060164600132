<div class="modal-dialog">
  <div class="modal-content">
    <div class="header">
      <div class="close">
        <svg-icon key="close" size="xs" (click)="close()"></svg-icon>
      </div>
      <div class="title">
        <h5>{{ 'DOCS.TITLE' | translate }}</h5>
      </div>
      <div class="action-btn source-container">
        <a ggpSmall (click)="openProjectDetails()" class="project-details-btn" color="primary" mat-stroked-button>
          <svg-icon key="document" size="sm"></svg-icon>
          {{ 'DOCS.PROJECT_DETAILS' | translate }}
        </a>
        <ggp-link [originalLinks]="docsOverviewDetails.originalLinks"></ggp-link>
      </div>
    </div>
    <div class="body">
      <div class="side-bar">
        <div [ggpDataLoading]="dataIsLoading | async">
          <span class="data-loader"></span>
        </div>
        <mat-tab-group animationDuration="200ms" (selectedTabChange)="setSelectedTab($event)" [selectedIndex]="docsWithKeyWords ? 1 : 0">
          <mat-tab *ngIf="allDocs" label="{{ 'DOCS.TAB.ALL_DOCS' | translate: { nbr: allDocs && allDocs.files.length > 0 ? allDocs.files.length : 0 } }}">
            <ng-template matTabContent>
              <ggp-all-documents [documentId]="docsOverviewDetails.documentId" [docs]="allDocs.files!" (displayPdfEvent)="displayPDF($event)"></ggp-all-documents>
            </ng-template>
          </mat-tab>
          <mat-tab
            *ngIf="docsWithKeyWords?.length"
            label="{{ 'DOCS.TAB.WITH_KEYWORD' | translate: { nbr: docsWithKeyWords.length } }}"
          >
            <ng-template matTabContent>
              <ggp-with-keywords
                [documentId]="docsOverviewDetails.documentId"
                [docsWithKeyWords]="docsWithKeyWords"
                (displayPdfEvent)="displayPDF($event)"
                [hasWithkeywordsProduct]="hasWithkeywordsProduct"
                [searchKeywords]="getSearchKeywords()"
                (highlighitedPdfEvent)="pdfScrollTo($event)"
              ></ggp-with-keywords>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="pdf-section" [ngClass]="{'file-loading': fileIsLoading | async}">
        <ggp-pdf
          *ngIf="(fileIsLoading | async) === false"
          [hasWithkeywordsProduct]="hasWithkeywordsProduct"
          [path]="pdfPath"
          [fileName]="selectedFileName"
          [highlightScroll]="highlightScroll"
        ></ggp-pdf>
        <div [ggpDataLoading]="fileIsLoading | async">
          <span class="data-loader"></span>
        </div>
      </div>
    </div>
  </div>
</div>
