import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { SubscriptionServiceInterface } from './models';
import { SubscriptionUser } from '@ggp/generic/shared/util/models';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService implements SubscriptionServiceInterface {
  getSubscriptionUsers(): Observable<SubscriptionUser[]> {
    throw new Error('Method not implemented.');
  }

  getMyTeam(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  get hideContactMessage(): boolean {
    return false;
  }

  get showNotifications(): boolean {
    return true;
  }

  get fetchUserDetails(): boolean {
    return true;
  }

  get showMyTeam(): boolean {
    return true;
  }
}
