import { Environment, QueueConfiguration } from './environment.model';

const AWS_ACCOUNT = '080304638101';
const AWS_REGION = 'eu-west-1';

export const environment: Environment = {
  production: false,
  envName: 'integration',
  awsAccount: AWS_ACCOUNT,
  awsRegion: AWS_REGION,
  tags: {
    'IPD:TECH:ENV': 'INTEGRATION',
    'IPD:BUSINESS:BU': 'INFOLEADS',
    'IPD:BUSINESS:CUSTOMER': 'EBP',
    'IPD:BUSINESS:PROJECT': 'GGP',
    'IPD:TECH:STACK': 'EBP/GGP',
    'map-migrated': 'mig36286',
  },
  api: {
    appsyncApiId: 'ljlnskwmtnbbtn643rxlt7r5ju',
    apiGqlUrl: 'https://api-ggpv2.integration.aws.ebp.be/graphql',
  },
  assets: {
    bucket: 'ggpv2-assets-integration',
    distributionId: 'E3KMCO5QBN1IQO',
    domainName: 'ggp-assets.integration.aws.ebp.be',
  },
  cognito: {
    admin: {
      userPoolId: 'eu-west-1_VPxyL9LRO',
      userPoolName: 'ggp-admin-users-integration',
    },
    abk: {
      userPoolId: 'eu-west-1_8ax2AVtvS',
      userPoolName: 'ggpv2-cognito-abk-users-integration',
    },
    ebp: {
      userPoolId: 'eu-west-1_n6UmjIqxM',
      userPoolName: 'ggpv2-cognito-ebp-users-integration',
    },
    mpf: {
      userPoolId: 'eu-west-1_5U5HQ3QyK',
      userPoolName: 'ggpv2-cognito-mpf-users-integration',
    },
  },
  dataBucket: 'ggp-ebp-integration-snowflake-exports',
  docDb: {
    secret: '/ggp/documentdb/masteruser',
    arn: 'arn:aws:secretsmanager:eu-west-1:080304638101:secret:/ggp/documentdb/masteruser-RkyigX',
  },
  docmanager: {
    credentials: {
      secret: '/ggp/docmanager/credentials',
      arn: 'arn:aws:secretsmanager:eu-west-1:080304638101:secret:/ggp/docmanager/credentials-ooKj70',
    },
    token: {
      secret: '/ggp/docmanager/token',
      arn: 'arn:aws:secretsmanager:eu-west-1:080304638101:secret:/ggp/docmanager/token-CsGgwJ',
    },
  },
  ecrion: {
    secret: '/ggp/ecrion',
    arn: 'arn:aws:secretsmanager:eu-west-1:080304638101:secret:/ggp/ecrion-gvFLCw',
    workspace: 'GGP_test',
    publicProjectPath: 'ebp-test/public-tender-timeline/main_public_tender_timeline.epr',
    privateProjectPath: 'ebp-test/private_project_timeline/main_private_project_timeline.epr',
  },
  justask: {
    secret: {
      name: '/ggp/justask',
      arn: 'arn:aws:secretsmanager:eu-west-1:080304638101:secret:/ggp/justask-VoWU6j',
    },
  },
  opensearch: {
    name: 'ggpv2-opensearch-integration',
    domainName: 'https://vpc-ggpv2-opensearch-integration-zsklxjz3g4l5wolg6pbus2ttke.eu-west-1.es.amazonaws.com/',
    dataImport: {
      maxConcurrency: 2,
    },
    secrets: {
      readOnly: {
        name: '/ggp/opensearch-ro',
        arn: 'arn:aws:secretsmanager:eu-west-1:080304638101:secret:/ggp/opensearch-ro-XyNNMU',
      },
      readWrite: {
        name: '/ggp/opensearch-rw',
        arn: 'arn:aws:secretsmanager:eu-west-1:080304638101:secret:/ggp/opensearch-rw-OoO0Zl',
      },
    },
  },
  vpc: {
    vpcId: 'vpc-0b3ff88c8f1d98b1e',
    privateSubnets: ['subnet-0d5b4a6cd115d245b', 'subnet-00cc4370b088f3557'],
  },
  bo: {
    apiGqlUrl: 'https://v3smhdt7qnf4fhjl732q4ifazu.appsync-api.eu-west-1.amazonaws.com/graphql',
    apiKey: 'da2-dwoedpphivfz3iptaycnw4ksze',
    brandinfoBaseUrl: 'https://assets-backoffice.integration.aws.ebp.be',
  },
  queues: {
    docManagerImport: {
      arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-docmanager-import-integration',
      url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-docmanager-import-integration',
      dlq: {
        arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-docmanager-import-deadletter-integration',
        url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-docmanager-import-deadletter-integration',
      },
    },
    docManagerFetch: {
      arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-docmanager-fetch-integration',
      url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-docmanager-fetch-integration',
      dlq: {
        arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-docmanager-fetch-deadletter-integration',
        url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-docmanager-fetch-deadletter-integration',
      },
    },
    documentImport: {
      arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-document-import-integration',
      url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-document-import-integration',
      dlq: {
        arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-document-import-deadletter-integration',
        url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-document-import-deadletter-integration',
      },
    },
    documentSearchmatch: {
      arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-document-searchmatch-integration',
      url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-document-searchmatch-integration',
      dlq: {
        arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-document-searchmatch-deadletter-integration',
        url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-document-searchmatch-deadletter-integration',
      },
    },
    documentSearchmatchResult: {
      arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-document-searchmatch-result-integration',
      url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-document-searchmatch-result-integration',
      dlq: {
        arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-document-searchmatch-result-deadletter-integration',
        url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-document-searchmatch-deadletter-integration',
      },
    },
    documentSearchmatchNotify: {
      arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-document-searchmatch-notify-integration',
      url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-document-searchmatch-notify-integration',
      dlq: {
        arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-document-searchmatch-notify-deadletter-integration',
        url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-document-searchmatch-notify-deadletter-integration',
      },
    },
    notification: {
      arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-email-notification-integration',
      url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-email-notification-integration',
      dlq: {
        arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-email-notification-deadletter-integration',
        url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-email-notification-deadletter-integration',
      },
    },
    notificationSender: {
      arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-email-notification-sender-integration',
      url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-email-notification-sender-integration',
      dlq: {
        arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-email-notification-sender-deadletter-integration',
        url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-email-notification-sender-deadletter-integration',
      },
    },
    sharingNotification: {
      arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-sharing-notification-integration',
      url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-sharing-notification-integration',
      dlq: {
        arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-sharing-notification-deadletter-integration',
        url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-sharing-notification-deadletter-integration',
      },
    },
    userActionLogs: {
      arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-user-actions-logs-integration',
      url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-user-actions-logs-integration',
      dlq: {
        arn: 'arn:aws:sqs:eu-west-1:080304638101:ggpv2-sqs-user-actions-logs-deadletter-integration',
        url: 'https://sqs.eu-west-1.amazonaws.com/080304638101/ggpv2-sqs-user-actions-logs-deadletter-integration',
      },
    },
    userDataHistoricalProfileMatches: new QueueConfiguration(
      AWS_REGION,
      AWS_ACCOUNT,
      'ggpv2-sqs-user-data-historical-profile-matches-integration',
      'ggpv2-sqs-user-data-historical-profile-matches-deadletter-integration',
    ),
    userDataOrganisationStatus: new QueueConfiguration(
      AWS_REGION,
      AWS_ACCOUNT,
      'ggpv2-sqs-user-data-organisation-status-integration',
      'ggpv2-sqs-user-data-organisation-status-deadletter-integration',
    ),
    userDataProfileMatches: new QueueConfiguration(
      AWS_REGION,
      AWS_ACCOUNT,
      'ggpv2-sqs-user-data-profile-matches-integration',
      'ggpv2-sqs-user-data-profile-matches-deadletter-integration',
    ),
    userDataProjectStatus: new QueueConfiguration(
      AWS_REGION,
      AWS_ACCOUNT,
      'ggpv2-sqs-user-data-project-status-integration',
      'ggpv2-sqs-user-data-project-status-deadletter-integration',
    ),
    userDataResetStatus: new QueueConfiguration(
      AWS_REGION,
      AWS_ACCOUNT,
      'ggpv2-sqs-user-data-reset-status-integration',
      'ggpv2-sqs-user-data-reset-status-deadletter-integration',
    ),
  },
  strapi: {
    arn: 'arn:aws:secretsmanager:eu-west-1:080304638101:secret:/ggp/strapi-SVq22r',
    name: '/ggp/strapi',
  },
  brand: {
    baseUrl: 'https://ggp-front-ebp.integration.aws.ebp.be/leads',
    publicUrl: '/public-projects?projectType=PUBLIC_TENDER&id=',
    privateUrl: '/construction-projects?projectType=PRIVATE_PROJECT&id=',
  },
  features: {
    showCompanies: true,
    showCurrentStageOnly: true,
    showJustAsk: true,
    showLeadsV2: true,
    showMigrationLogin: true,
    showDemoProfile: true,
    showSaveSearchNotification: true,
    showProjectHistorySearch: true,
    useNewHighlight: true,
    useBoEndpointApiProfileById: true,
    useNewKeywords: true,
    showBONewRegionDialog: false,
    useNewTags: false
  },
};
