import { catchError, map, of, switchMap } from 'rxjs';

import { inject } from '@angular/core';
import { NavigationCancel, Router } from '@angular/router';
import { BRAND_TOKEN } from '@ggp/generic/shared/config/token';
import { ProductsService, SubscriptionInvoiceStatus } from '@ggp/generic/shared/services';
import { AuthenticationService } from '../services/authentication.service';
import { ProductType } from '@ggp/generic/shared/util/models';

export const AuthGuard = () => {
  const authenticationService = inject(AuthenticationService);
  const productsService = inject(ProductsService);
  const router = inject(Router);
  const brandToken = inject(BRAND_TOKEN);

  router.events.subscribe(event => {
    if (event instanceof NavigationCancel) authenticationService.deepLink = event.url;
  });

  if (authenticationService.cognitoUser) {
    if (!brandToken.isBackOffice) {
      return authenticationService.getSubscriptionStatus().pipe(
        switchMap(response => {
          localStorage.setItem('subscriptionStatus', response.invoice_status);
          if (response.invoice_status === SubscriptionInvoiceStatus.BLOCKED_UNPAID) {
            router.navigate(['/auth/unpaid']);

            return of(false);
          } else {
            if (authenticationService.signInCognitoUser?.nextStep.signInStep === 'DONE') {
              authenticationService.signInCognitoUser = null;

              return productsService.getProducts().pipe(
                map(({ data }) => {
                  const hasPublic = data?.some(product => product.product_id === ProductType.PUBLIC_TENDER);
                  const hasPrivate = data?.some(product => product.product_id === ProductType.PRIVATE_PROJECT);

                  if (hasPrivate && !hasPublic) router.navigate(['/leads/construction-projects']);
                  if (!hasPrivate && !hasPublic) router.navigate(['/leads/public-projects']);

                  return of(true);
                }),
                catchError(error => {
                  console.error('Failed to initialize products', error);

                  return of(false);
                }),
              );
            } else {
              return of(true);
            }
          }
        }),
      );
    }

    return true;
  }

  router.navigate(['/auth/login']);
  return false;
};
