import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ROUTE_CONFIG_TOKEN } from '@ggp/generic/shared/config/token';
import { FilterService } from '@ggp/generic/shared/services';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLintActiveIgnoreQueryDirective } from '@ggp/generic/shared/directives/router-link-active-ignore-query';

@Component({
  selector: 'ggp-left-bar',
  standalone: true,
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.scss'],
  imports: [RouterModule, SvgIconComponent, NgFor, NgIf, AsyncPipe, TranslateModule, RouterLintActiveIgnoreQueryDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftBarComponent {
  items$ = inject(ROUTE_CONFIG_TOKEN);
  readonly #filterService = inject(FilterService);

  clearFilters() {
    this.#filterService.clearAll();
  }
}
