import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Filters, Language, ProjectType, Sort } from '@ggp/generic/shared/util/models';

import { CompanyDetailViewData, DetailViewData } from './models';
import { Leads } from './models/interfaces/leads.interface';

@Injectable({
  providedIn: 'root',
})
export class LeadsService implements Leads {
  detailViewData$ = new BehaviorSubject<DetailViewData | CompanyDetailViewData | null>(null);

  getProjects(projectType: string, pageNumber: number, language: string, sort?: Sort, filters?: Filters): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getProjectStatus(projectId: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getPublicProfiles(projectType: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getPresigneUrl(projectIds: string[], language: string, columns: string[], excelType: string, projectType: ProjectType, refLabel: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getProjectGeneralInfoById(projectId: string, language: string, stage: string, keywords?: string[]): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getProjectPublicationTextById(projectId: string, stage: string, keywords?: string[]): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getAuthorities(projectId: string, language: string, allContacts: boolean): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getProjectBottomSectionById(projectId: string, language: string, stage: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getProjectLotsById(projectId: string, stage: string, organisationId?: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getProjectRenewalDates(projectId: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getProjectRectificationsQuery(projectId: string, keywords?: string[]): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getProjectLocations(projectId: string, language: string): Observable<Location> {
    throw new Error('Method not implemented.');
  }
  getContactBoxDetails(projectId: string, language: string, allContacts: boolean): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getPublicDetailView(projectId: string, stage: string, language: Language, allContacts: boolean, keywords?: string[]): Observable<any> {
    throw new Error('Method not implemented.');
  }
  getConstructionDetailView(projectId: string | number, stage: string, language: Language, allContacts: boolean, keywords?: string[]): Observable<any> {
    throw new Error('Method not implemented.');
  }
  updateDetailViewData(data: DetailViewData | CompanyDetailViewData | null): void {
    throw new Error('Method not implemented.');
  }
}
