import { Route, Routes } from '@angular/router';
import { FeatureFlag } from '@ggp/ebp/environment';

export interface ConditionalRoute extends Route {
  flag?: boolean;
  children?: ConditionalRoute[];
}

export interface ProcessedEnvironment {
  features: Record<keyof FeatureFlag, boolean>;
}

export interface NavigationRoute {
  id: number;
  key: string;
  title?: string;
  data_test_id?: string;
  redirection: string;
  queryParams?: Record<string, string>;
  flag?: boolean;
}

export function isRouteEnabled(route: ConditionalRoute): boolean {
  return route.flag !== false;
}

export function processRoutes(routes: ConditionalRoute[]): Routes {
  return routes.filter(isRouteEnabled).map(route => {
    const processedRoute = { ...route };
    if (processedRoute.children) {
      processedRoute.children = processRoutes(processedRoute.children);
    }
    delete processedRoute.flag;

    return processedRoute;
  });
}

export function generateNavigationRoutes(routes: NavigationRoute[]): Omit<NavigationRoute, 'flag'>[] {
  const helpSection = routes.find(route => route.redirection === '/help');

  const filteredRoutes = routes
    .filter(route => route.redirection !== '/help' && route.flag !== false)
    .map((route, index) => {
      const { flag, ...routeWithoutFlag } = route;
      return {
        ...routeWithoutFlag,
        id: index,
      };
    });

  if (helpSection) {
    const { flag, ...helpSectionWithoutFlag } = helpSection;
    filteredRoutes.push({
      ...helpSectionWithoutFlag,
      id: filteredRoutes.length,
    });
  }

  return filteredRoutes;
}
