export enum SliderType {
  DATE = 'date',
  RANGE = 'range',
}

export interface DateEmitter {
  minDate: Date | null;
  maxDate: Date | null;
  dateType: SliderType | undefined;
}
