import { Pipe, PipeTransform } from '@angular/core';

import { NormalizeTextPipe } from './normalize.pipe';

@Pipe({
  name: 'highlight',
  standalone: true,
})
export class HighlightPipe implements PipeTransform {
  private normalizeTextPipe = new NormalizeTextPipe();

  transform(text: string, search: string): string {
    if (!search?.trim() || !text) return text;

    const normalizedText = this.normalizeTextPipe.transform(text);
    const regex = new RegExp(search, 'gi');
    let lastIndex = 0;
    let result = '';

    normalizedText.replace(regex, (match: string, index: number) => {
      const originalText = text.slice(index, index + match.length);
      result += text.slice(lastIndex, index) + `<span class="highlight">${originalText}</span>`;
      lastIndex = index + match.length;
      return match;
    });

    return result + text.slice(lastIndex);
  }
}


