export enum QueryParamKeys {
  ProfileId = 'id',
  SubscriptionId = 'subscription_id',
  Brand = 'brand',
  Folder = 'folder',
  Name = 'name',
  Description = 'description',
  ProjectType = 'projectType',
  SearchProfileType = 'search_profile_type',
  SubscriptionProfileType = 'subscription_profile_type',
  StartDate = 'start_date',
  StopDate = 'stop_date',
  ProjectId = 'id',
  SubFolderId = 'subFolderId',
  IsDemo = 'isDemo',
  Notification = 'notification',
}
