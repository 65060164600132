import { DecimalPipe } from '@angular/common';
import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedNumber',
  standalone: true,
  pure: false,
})
@Injectable({ providedIn: 'root' })
export class LocalizedNumberPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);

  transform(value: string | number) {
    return new DecimalPipe(this.translateService.currentLang).transform(value, '1.0-3');
  }
}
