import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ShareProject, ShareProjectResult } from '@ggp/generic/shared/util/models';
import { ShareProjectMethods } from '@ggp/generic/shared/services';

@Injectable({
  providedIn: 'root',
})
export class ShareProjectService implements ShareProjectMethods {
  shareProject(shareProject: ShareProject): Observable<ShareProjectResult> {
    throw new Error('Method not implemented.');
  }
}
