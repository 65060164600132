import { Apollo } from 'apollo-angular';
import { concatMap, EMPTY, map, of, Subject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { AddNewTag, RemoveTag, Tag } from '@ggp/generic/shared/util/models';

import { AddTagsMutationResponse, TagsQuery } from './models/tags-query';
import { addTagsMutationGQL, getSubscriptionTagsGQL, removeTagFromProjectMutationGQL } from './schemas/tags.schema';

interface ProjectTag {
  projectId: string;
  projectTags: Tag[];
}

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  readonly #apollo = inject(Apollo);

  #itemAddedSubject = new Subject<AddNewTag>();
  itemAdded$ = this.#itemAddedSubject.asObservable();
  #itemDeletedSubject = new Subject<RemoveTag>();
  itemDeleted$ = this.#itemDeletedSubject.asObservable();
  selectedProjectLabels?: ProjectTag[];

  getSubscriptionTags() {
    return this.#apollo
      .query<TagsQuery>({
        query: getSubscriptionTagsGQL,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }) => {
          return data?.getSubscriptionTagsQuery;
        }),
      );
  }

  addTagToProject(subscriptionData: AddNewTag) {
    return this.#apollo
      .mutate<AddTagsMutationResponse>({
        mutation: addTagsMutationGQL,
        variables: {
          subscriptionData,
        },
        optimisticResponse: {
          addTagsMutation: {
            statusCode: 200,
            message: 'success',
          },
        },
      })
      .pipe(
        concatMap((result: any) => {
          this.#itemAddedSubject.next(subscriptionData);
          return of(result);
        }),
      );
  }

  removeTagFromProject(subscriptionData: RemoveTag) {
    return this.#apollo
      .mutate<AddTagsMutationResponse>({
        mutation: removeTagFromProjectMutationGQL,
        variables: {
          subscriptionData,
        },
        optimisticResponse: {
          removeTagFromProjectMutation: {
            statusCode: 200,
            message: 'success',
          },
        },
      })
      .pipe(
        concatMap((result: any) => {
          this.#itemDeletedSubject.next(subscriptionData);
          return of(result);
        }),
      );
  }

  editTag(tag: Tag) {
    // TODO implement when BE ready GGP-5907
    return of(EMPTY);
  }

  removeTag(tag: Tag) {
    // TODO implement when BE ready GGP-5907
    return of(EMPTY);
  }
}
