import { map, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrandWithVersion } from '@ggp/ebp-bo/shared/constants';
import { QueryParamKeys } from '@ggp/ebp-bo/shared/models';
import { SearchProfileType, SubscriptionProfileType } from '@ggp/generic/common';
import { Folders, ProjectType } from '@ggp/generic/shared/util/models';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  readonly #activatedRoute = inject(ActivatedRoute);

  getParam(name: string): Observable<string> {
    return this.#activatedRoute.queryParams.pipe(map(data => data[name]));
  }

  get queryParams(): {
    profileId: number;
    subscriptionId: number;
    brand: BrandWithVersion;
    folder: Folders;
    name: string;
    description: string;
    projectType: ProjectType;
    searchProfileType: SearchProfileType;
    subscriptionProfileType: SubscriptionProfileType;
    startDate?: string;
    stopDate?: string;
    notification?: string;
  } {
    const { queryParams } = this.#activatedRoute.snapshot;
    return {
      profileId: +queryParams[QueryParamKeys.ProfileId],
      subscriptionId: +queryParams[QueryParamKeys.SubscriptionId],
      brand: queryParams[QueryParamKeys.Brand],
      folder: queryParams[QueryParamKeys.Folder],
      name: queryParams[QueryParamKeys.Name],
      description: queryParams[QueryParamKeys.Description],
      projectType: queryParams[QueryParamKeys.ProjectType],
      searchProfileType: queryParams[QueryParamKeys.SearchProfileType],
      subscriptionProfileType: queryParams[QueryParamKeys.SubscriptionProfileType],
      startDate: queryParams[QueryParamKeys.StartDate],
      stopDate: queryParams[QueryParamKeys.StopDate],
      notification: queryParams[QueryParamKeys.Notification],
    };
  }
}
