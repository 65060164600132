<div *ngIf="docs">
  <mat-form-field ggpLarge color="primary" class="search-field">
    <svg-icon key="filter-by" size="md" matPrefix></svg-icon>
    <input #docsSearch matInput [placeholder]="'DOCS.FILTER_BY_NAME'| translate" [(ngModel)]="searchText" />
  </mat-form-field>
  <div class="select-all-items">
    <mat-checkbox
      color="primary"
      (change)="$event ? toggleAllDocuments() : null"
      [checked]="selectedFiles.hasValue() && isAllSelected()"
      [indeterminate]="selectedFiles.hasValue() && !isAllSelected()"
      [disabled]="!(filteredDocs$ | async)?.length"
    >
      <p class="body-2">
        {{ selectedFiles.selected.length ? ('DOCS.SELECTED' | translate: { nbr: selectedFiles.selected.length }) : ('DOCS.SELECT_ALL' | translate) }}
      </p>
    </mat-checkbox>
    <button
      ggpSmall
      [ggpLoading]="downloadFilesIsLoading"
      [disabled]="!(filteredDocs$ | async)?.length"
      color="primary"
      mat-flat-button
      type="submit"
      (click)="downloadSelectedFiles()"
    >
      <svg-icon key="download" size="sm"></svg-icon>
      {{ selectedFiles.selected.length ? ('DOCS.DOWNLOAD_FILES' | translate) : ('DOCS.DOWNLOAD_THE_DOCS' | translate) }}
    </button>
  </div>
  <div>
    <ng-container *ngIf="(filteredDocs$ | async)?.length; else noDocument">
      <div class="select-items" *ngFor="let doc of (filteredDocs$ | async); let i = index" (mouseenter)="onMouseEnter(doc)" (mouseleave)="onMouseLeave()">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? selectedFiles.toggle(doc) : null"
          [checked]="selectedFiles.isSelected(doc)"
        >
          <p
            class="doc-name"
            [ngClass]="[currentItemHovered(doc) ? 'subtitle-2' : 'body-2', doc.id === currentOpenFile?.id ? 'clicked-title' : '']"
            (click)="displayPdf($event, doc)"
          >
            <span [innerHTML]="(doc.name | highlightKeyword: searchText: doc.extension)"></span>
          </p>
        </mat-checkbox>
        <ng-container *ngIf="doc.isLoading; else downloadIcon">
          <mat-spinner diameter="24"></mat-spinner>
        </ng-container>
        <ng-template #downloadIcon>
          <svg-icon *ngIf="shouldShowDownloadIcon(i, doc)" key="download" size="sm" class="download-icon" (click)="downloadFile(doc)"></svg-icon>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #noDocument>
      <ng-container *ngIf="searchText !== ''">
        <div class="no-document">
          <svg-icon key="tender" size="md"></svg-icon>
          <p class="body-1">
            {{"DOCS.NO_DOCUMENT" | translate}}
          </p>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
