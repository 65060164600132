import { BrandConfig, ConfigPerBrand } from './profile-details.model';

export enum BrandWithVersion {
  VPL = 'VPL',
  EBP_V2 = 'EBP_V2',
  ABK_V2 = 'ABK_V2',
  ABK = 'ABK',
  MPF = 'MPF',
}

export const DEFAULT_BRAND_CONFIG: BrandConfig = {
  publicRoutes: [
    { path: 'overview', title: 'PROFILE.DETAILS.OVERVIEW.TITLE' },
    { path: 'location', title: 'PROFILE.DETAILS.LOCATION.TITLE' },
    { path: 'cpv', title: 'PROFILE.DETAILS.CPV.TITLE' },
    { path: 'activity', title: 'PROFILE.DETAILS.ACTIVITY.TITLE' },
    { path: 'class', title: 'PROFILE.DETAILS.CLASS.TITLE' },
    { path: 'scope', title: 'PROFILE.DETAILS.SCOPE.TITLE' },
    { path: 'source', title: 'PROFILE.DETAILS.SOURCE.TITLE' },
    { path: 'stage', title: 'PROFILE.DETAILS.PHASES.TITLE' },
    { path: 'keyword', title: 'PROFILE.DETAILS.KEYWORD.TITLE' },
    { path: 'history', title: 'PROFILE.DETAILS.HISTORY.TITLE' },
  ],
  privateRoutes: [
    { path: 'overview', title: 'PROFILE.DETAILS.OVERVIEW.TITLE' },
    { path: 'execution-site', title: 'PROFILE.DETAILS.LOCATION.EXECUTION_SITES' },
    { path: 'building-types', title: 'PROFILE.DETAILS.BUILDING_TYPE.TITLE' },
    { path: 'progress-status', title: 'PROFILE.DETAILS.PROGRESS_STATUS.TITLE' },
    { path: 'project-type', title: 'PROFILE.DETAILS.PROJECT_TYPE.TITLE' },
    { path: 'work-type', title: 'PROFILE.DETAILS.WORK_TYPE.TITLE' },
    { path: 'source', title: 'PROFILE.DETAILS.SOURCE.TITLE' },
    { path: 'keyword', title: 'PROFILE.DETAILS.KEYWORD.TITLE' },
    { path: 'history', title: 'PROFILE.DETAILS.HISTORY.TITLE' },
  ],
};

export const CONFIG_PER_BRAND: ConfigPerBrand = {
  [BrandWithVersion.ABK_V2]: DEFAULT_BRAND_CONFIG,
  [BrandWithVersion.ABK]: DEFAULT_BRAND_CONFIG,
  [BrandWithVersion.EBP_V2]: DEFAULT_BRAND_CONFIG,
  [BrandWithVersion.MPF]: {
    publicRoutes: [
      { path: 'overview', title: 'PROFILE.DETAILS.OVERVIEW.TITLE' },
      { path: 'location', title: 'PROFILE.DETAILS.LOCATION.TITLE' },
      { path: 'cpv', title: 'PROFILE.DETAILS.CPV.TITLE' },
      { path: 'activity', title: 'PROFILE.DETAILS.ACTIVITY.TITLE' },
      { path: 'source', title: 'PROFILE.DETAILS.SOURCE.TITLE' },
      { path: 'stage', title: 'PROFILE.DETAILS.PHASES.TITLE' },
      { path: 'keyword', title: 'PROFILE.DETAILS.KEYWORD.TITLE' },
      { path: 'history', title: 'PROFILE.DETAILS.HISTORY.TITLE' },
    ],
    privateRoutes: [
      { path: 'overview', title: 'PROFILE.DETAILS.OVERVIEW.TITLE' },
      { path: 'execution-site', title: 'PROFILE.DETAILS.LOCATION.EXECUTION_SITES' },
      { path: 'building-types', title: 'PROFILE.DETAILS.BUILDING_TYPE.TITLE' },
      { path: 'progress-status', title: 'PROFILE.DETAILS.PROGRESS_STATUS.TITLE' },
      { path: 'project-type', title: 'PROFILE.DETAILS.PROJECT_TYPE.TITLE' },
      { path: 'keyword', title: 'PROFILE.DETAILS.KEYWORD.TITLE' },
      { path: 'history', title: 'PROFILE.DETAILS.HISTORY.TITLE' },
    ],
  },
  [BrandWithVersion.VPL]: DEFAULT_BRAND_CONFIG,
};
