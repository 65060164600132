import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform<T>(items: T[] = [], searchTerm: string, key: keyof T = 'value' as keyof T): T[] {
    return !searchTerm
      ? items
      : items.filter(item => {
          const itemValue = item[key];
          return itemValue && itemValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
        });
  }
}
