export type FrontendBrand = 'ABK' | 'EBP' | 'MPF' | 'VPL';

export interface FeatureFlag {
  showCompanies: boolean;
  showCurrentStageOnly: boolean;
  showJustAsk: boolean;
  showLeadsV2: boolean;
  showMigrationLogin: boolean;
  showDemoProfile: boolean;
  showSaveSearchNotification: boolean;
  showProjectHistorySearch: boolean;
  useNewKeywords: boolean,
  useNewHighlight: boolean;
  useBoEndpointApiProfileById: boolean;
  showBONewRegionDialog: boolean;
  useNewTags: boolean;
}

export interface FeatureFlagBackend {
  useBoEndpointApiProfileById: boolean;
  [key: string]: boolean;
}

export interface Environment {
  production: boolean;
  envName: string;
  awsAccount: string;
  awsRegion: string;
  tags: { [key: string]: string };
  api: {
    appsyncApiId: string;
    apiGqlUrl?: string;
  };
  assets: {
    bucket: string;
    distributionId: string;
    domainName: string;
  };
  bo: {
    apiGqlUrl: string;
    apiKey: string;
    brandinfoBaseUrl: string;
  };
  cognito: {
    admin: CognitoUserPoolConfig;
    abk: CognitoUserPoolConfig;
    ebp: CognitoUserPoolConfig;
    mpf: CognitoUserPoolConfig;
  };
  dataBucket: string;
  docDb: {
    secret: string;
    arn: string;
  };
  docmanager: {
    credentials: {
      secret: string;
      arn: string;
    };
    token: {
      secret: string;
      arn: string;
    };
  };
  ecrion: {
    secret: string;
    arn: string;
    workspace: string;
    publicProjectPath: string;
    privateProjectPath: string;
  };
  justask: {
    secret: {
      name: string;
      arn: string;
    };
  };
  opensearch: {
    name: string;
    domainName: string;
    dataImport: {
      maxConcurrency: number;
    };
    secrets: {
      readOnly: {
        name: string;
        arn: string;
      };
      readWrite: {
        name: string;
        arn: string;
      };
    };
  };
  queues: {
    docManagerImport: QueueConfig;
    docManagerFetch: QueueConfig;
    documentImport: QueueConfig;
    documentSearchmatch: QueueConfig;
    documentSearchmatchResult: QueueConfig;
    documentSearchmatchNotify: QueueConfig;
    notification: QueueConfig;
    notificationSender: QueueConfig;
    userDataHistoricalProfileMatches?: QueueConfiguration;
    userDataOrganisationStatus?: QueueConfiguration;
    userDataProfileMatches?: QueueConfiguration;
    userDataProjectStatus?: QueueConfiguration;
    userDataResetStatus?: QueueConfiguration;
    sharingNotification: QueueConfig;
    userActionLogs: QueueConfig;
  };
  vpc: {
    vpcId: string;
    privateSubnets: string[];
  };
  strapi: {
    name: string;
    arn: string;
  };
  brand: {
    baseUrl: string;
    publicUrl: string;
    privateUrl: string;
  };
  features: FeatureFlag;
}

export type QueueConfig = {
  arn: string;
  url: string;
  dlq?: QueueConfig;
};

export class QueueConfiguration {
  public arn: string;
  public url: string;
  public dlq: QueueConfiguration | undefined;

  constructor(private awsRegion: string, private awsAccount: string, private queueName: string, private _dlq?: string) {
    this.arn = `arn:aws:sqs:${this.awsRegion}:${this.awsAccount}:${this.queueName}`;
    this.url = `https://sqs.${this.awsRegion}.amazonaws.com/${this.awsAccount}/${this.queueName}`;
    this.dlq = this._dlq ? new QueueConfiguration(this.awsRegion, this.awsAccount, this._dlq) : undefined;
  }
}

export type CognitoUserPoolConfig = {
  userPoolId: string;
  userPoolName: string;
};

export interface EnvironmentBackoffice {
  production: boolean;
  version: string;
  didomi_id: string;
  gtag_id: string;
  endpoints: {
    app: AppEndpoint;
    mapbox: MapboxEndpoint;
    platform_url: {
      abk: string;
      ebp: string;
      mpf: string;
    };
    strapi: {
      url: string;
    };
  };
  features: FeatureFlag;
}

export interface EnvironmentFrontend {
  production: boolean;
  version: string;
  didomi_id: string;
  gtag_id: string;
  endpoints: {
    app: AppEndpoint;
    mapbox: MapboxEndpoint;
    strapi: {
      url: string;
    };
  };
  features: FeatureFlag;
}

export interface AppEndpoint {
  uri: string;
  uriv2?: string;
  Auth: {
    Cognito: {
      userPoolId: string;
      userPoolClientId: string;
    };
  };
}

export interface MapboxEndpoint {
  geocoding_url: string;
  access_token: string;
  style: {
    street: string;
  };
}
