import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { TreeNode } from '@ggp/generic/shared/util/models';

@Injectable({
  providedIn: 'root',
})
export class FilterListService {
  getFilterList(filterKey: string): Observable<TreeNode[]> {
    throw new Error('Method not implemented.');
  }
}
