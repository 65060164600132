import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { User } from '@ggp/generic/common';
import { SubscriptionServiceInterface } from '@ggp/generic/shared/services';
import { SubscriptionUser } from '@ggp/generic/shared/util/models';

import { getMyTeamGQL, getUsersGQL } from '../schema/subscription.schema';

@Injectable({
  providedIn: 'root',
})
export class EbpSubscriptionService implements SubscriptionServiceInterface {
  readonly #apollo = inject(Apollo);

  get showNotifications(): boolean {
    return true;
  }

  get showMyTeam(): boolean {
    return true;
  }

  get fetchUserDetails(): boolean {
    return true;
  }

  getSubscriptionUsers() {
    return this.#apollo
      .query<{ getUsersQuery: SubscriptionUser[] }>({
        query: getUsersGQL,
      })
      .pipe(map(({ data }) => data?.getUsersQuery));
  }

  getMyTeam() {
    return this.#apollo
      .query<{ getUsersQuery: User[] }>({
        query: getMyTeamGQL,
        fetchPolicy: 'no-cache',
      })
      .pipe(map(({ data }) => data?.getUsersQuery));
  }
}
