import { Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { ProjectGeneralInfo } from '@ggp/ebp/leads/util';
import { LocalizedDatePipe, CompareDatePipe } from '@ggp/generic/shared/pipes';
import { LabelsService } from '@ggp/generic/shared/services';
import { Region, RemoveTag, Tag } from '@ggp/generic/shared/util/models';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { PLATFORM_CONFIG_TOKEN } from '@ggp/generic/shared/config/token';
import { FormatCostPipe } from './format-cost/format-cost.pipe';

@Component({
  selector: 'ggp-construction-general-information',
  standalone: true,
  imports: [CommonModule, TranslateModule, LocalizedDatePipe, SvgIconComponent, MatButtonModule, MatChipsModule, CompareDatePipe, FormatCostPipe],
  templateUrl: './construction-general-information.component.html',
  styleUrls: ['./construction-general-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConstructionGeneralInformationComponent implements AfterViewInit, OnInit, OnDestroy {
  readonly #cd = inject(ChangeDetectorRef);
  readonly #labelService = inject(LabelsService);
  readonly #onDestroy = new Subject();
  readonly #renderer = inject(Renderer2);
  readonly maxNumberOfLines = 4;
  readonly columns = inject(PLATFORM_CONFIG_TOKEN).constructionProjectDetailsColumns;

  @Input() currentStage!: string;
  @Input() projectGeneralInfo!: Observable<ProjectGeneralInfo>;

  @ViewChildren('section') sectionList!: QueryList<ElementRef>;

  ngOnInit(): void {
    let newTags: Tag[] = [];
    this.#labelService.itemAdded$
      .pipe(
        takeUntil(this.#onDestroy),
        switchMap(tagsToAdd => {
          newTags = tagsToAdd.tags;
          return this.projectGeneralInfo;
        }),
      )
      .subscribe(generalInfo => {
        const tagList = generalInfo.tags ? generalInfo.tags : [];
        newTags.forEach(tag => tagList.push(tag));
        this.projectGeneralInfo = of({ ...generalInfo, tags: tagList });
        this.#cd.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this.sectionList.changes.pipe(takeUntil(this.#onDestroy)).subscribe(elements => {
      elements.forEach((element: ElementRef) => {
        const paragraphs = element.nativeElement.querySelectorAll('p');

        if (paragraphs.length > 2) {
          const textElement = paragraphs[1];
          const actionElement = paragraphs[2];
          const nbOfLines = textElement.offsetHeight / parseInt(textElement.style.lineHeight);

          if (nbOfLines <= this.maxNumberOfLines) {
            this.#renderer.setStyle(actionElement, 'display', 'none');
          } else {
            this.#renderer.addClass(textElement, 'closed');
            const hideActionElement = actionElement.querySelectorAll('span')[1];
            this.#renderer.setStyle(hideActionElement, 'display', 'none');
          }
        }
      });
    });
  }

  handleAction(className: string, actionName: string) {
    const element = this.sectionList.find(e => e.nativeElement.className.includes(className));
    if (!element) return;

    const isShowAction = actionName === 'show';

    const paragraphs = element.nativeElement.querySelectorAll('p');

    const textElement = paragraphs[1];
    isShowAction ? this.#renderer.removeClass(textElement, 'closed') : this.#renderer.addClass(textElement, 'closed');

    const actionElement = paragraphs[2];

    const showActionElement = actionElement.querySelectorAll('span')[0];
    isShowAction ? this.#renderer.setStyle(showActionElement, 'display', 'none') : this.#renderer.setStyle(showActionElement, 'display', 'block');

    const hideActionElement = actionElement.querySelectorAll('span')[1];
    isShowAction ? this.#renderer.setStyle(hideActionElement, 'display', 'block') : this.#renderer.setStyle(hideActionElement, 'display', 'none');
  }

  remove(projectId: string, label: Tag): void {
    this.projectGeneralInfo.subscribe(info => {
      const updatedTags = info.tags.filter((tag: Tag) => tag !== label);
      this.projectGeneralInfo = of({ ...info, tags: updatedTags });
      const tagToRemove: RemoveTag = {
        projectId: projectId,
        tagToRemove: {
          label: label.label,
          color: label.color,
        },
      };

      this.#labelService.removeTagFromProject(tagToRemove).subscribe({
        next: () => {
          // update list of tags
        },
      });
    });
  }

  getLabelBorder(color: string) {
    return 'border-color: ' + color + '!important';
  }

  isColumnVisible(name: string) {
    return this.columns.includes(name);
  }

  getRegions(regions: Region[]) {
    return [...new Set(regions.map(region => region.value))];
  }

  ngOnDestroy(): void {
    this.#onDestroy.next(null);
    this.#onDestroy.complete();
  }
}
