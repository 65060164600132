import { map, Observable, Subject, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from '@angular/core';
import { ProjectPublicationText } from '@ggp/ebp/leads/util';
import { LanguageTabComponent } from '@ggp/generic/shared/components/language-tab';
import { HighlightKeywordPipe } from '@ggp/generic/shared/pipes';
import { FilterService, LocaleLanguageService } from '@ggp/generic/shared/services';
import { Language as LocalLanguage } from '@ggp/generic/shared/util/models';
import { TranslateModule } from '@ngx-translate/core';

interface Language {
  name: string;
  isSelected: boolean;
  isNull: boolean;
}
@Component({
  selector: 'ggp-publication-text',
  standalone: true,
  imports: [CommonModule, LanguageTabComponent, TranslateModule, HighlightKeywordPipe],
  templateUrl: './publication-text.component.html',
  styleUrls: ['./publication-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationTextComponent implements OnInit {
  @Input() projectPublicationText$!: Observable<ProjectPublicationText>;
  @Input() sectionTitle = 'PUBLIC.DETAIL.PUBLICATION_TEXT';

  readonly #localeLanguageService = inject(LocaleLanguageService);
  readonly #filterService = inject(FilterService);
  readonly #cd = inject(ChangeDetectorRef);
  selectedLanguage!: string;
  selectedContent$!: Observable<ProjectPublicationText>;
  languages$!: Observable<Language[]>;
  searchKeywords: string[] = [];
  readonly #onDestroy = new Subject();

  ngOnInit(): void {
    this.languages$ = this.projectPublicationText$.pipe(
      map((text: any) => {
        const orderedLanguages = this.orderLanguages(this.#localeLanguageService.getLocaleLanguage());

        const selectedLang = orderedLanguages.find(l => text[l] !== null) || 'nl';
        this.selectedLanguage = selectedLang;

        return orderedLanguages.map(lang => ({
          name: lang,
          isSelected: lang === selectedLang,
          isNull: text[lang] === null,
        }));
      }),
    );
    const keywords = this.#filterService.getFiltersValues().keywords || [];
    this.#filterService.selectedProfileKeywordsObs$.pipe(takeUntil(this.#onDestroy)).subscribe((profileKeywords: string[]) => {
      if (Array.isArray(profileKeywords) && Array.isArray(keywords)) {
        this.searchKeywords = [...new Set([...profileKeywords, ...keywords])];
        this.#cd.detectChanges();
      }
    });
  }

  selectedLang(lang: string) {
    this.selectedLanguage = lang.toLowerCase();
  }

  getContent(selectedContent: ProjectPublicationText) {
    switch (this.selectedLanguage) {
      case 'en':
        return this.addClasses(selectedContent?.en);

      case 'fr':
        return this.addClasses(selectedContent?.fr);

      case 'nl':
        return this.addClasses(selectedContent?.nl);

      case 'de':
        return this.addClasses(selectedContent?.de);

      default:
        return this.addClasses(selectedContent?.nl);
    }
  }
  addClasses(input: string) {
    const regexH1 = /<h1([^>]*)>/g;
    let modifiedContent = input?.replace(regexH1, `<h6>`) || '';

    const regexH2 = /<h2([^>]*)>/g;
    modifiedContent = modifiedContent?.replace(regexH2, `<h2 class="subtitle-1">`);

    const regexH3 = /<h3([^>]*)>/g;
    modifiedContent = modifiedContent?.replace(regexH3, `<h3 class="subtitle-1">`);

    const regexH4 = /<h4([^>]*)>/g;
    modifiedContent = modifiedContent?.replace(regexH4, `<h4 class="subtitle-2">`);

    const regexH5 = /<h5([^>]*)>/g;
    modifiedContent = modifiedContent?.replace(regexH5, `<h4 class="subtitle-2">`);

    const elementsToRemove = ['Contactpersonen', 'Betrokken partijen', 'Intervenants', 'Contacts'];

    elementsToRemove.forEach(element => {
      const regex = new RegExp(`<hr>\\s*(<h4 class="subtitle-2">${element}<\\/h4>|<h5 class="subtitle-2">${element}<\\/h5>)\\s*<div[^>]*>.*?<\\/div>`, 'gs');
      modifiedContent = modifiedContent?.replace(regex, '');
    });

    return modifiedContent;
  }

  orderLanguages(lang: LocalLanguage) {
    switch (lang) {
      case LocalLanguage.en:
        return ['en', 'nl', 'fr', 'de'];

      case LocalLanguage.fr:
        return ['fr', 'nl', 'en', 'de'];

      case LocalLanguage.nl:
        return ['nl', 'fr', 'en', 'de'];

      case LocalLanguage.de:
        return ['de', 'nl', 'fr', 'en'];

      default:
        return ['nl', 'fr', 'en', 'de'];
    }
  }
}
