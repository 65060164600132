import { Type } from '@angular/core';

import { SliderType } from './interfaces';

export interface Filters {
  isDemoProfile?: boolean;
  keywords?: string[];
  stages?: Stage[];
  tags?: string[];
  languages?: string[];
  regions?: string[];
  scopes?: Scope[];
  activities?: string[];
  cpvs?: string[];
  lastUpdateDate?: DateRange;
  deadlineDate?: Range;
  classes?: string[];
  projectTypes?: string[];
  buildingTypes?: string[];
  startDate?: Range;
  deleted?: boolean;
  favorite?: boolean;
  read?: boolean;
  profileId?: string;
  profiles?: number[];
  organisationId?: string;
  isPerformancePlace?: boolean;
}

export interface SavedFilters extends Filters {
  deadline?: DateTimeRange;
  lastModificationDate?: DateTimeRange;
  publishDate?: DateTimeRange;
  [key: string]: string[] | Stage[] | string | object | boolean | Range | undefined;
}

interface DateTimeRange {
  dateFrom: Date;
  dateTo?: Date;
  type?: DateRangeType;
  dateType?: SliderType;
}

export interface Range {
  minValue?: string;
  maxValue?: string;
  optional?: boolean;
  dateType?: SliderType;
}

export interface ValueRange {
  minValue?: number;
  maxValue?: number;
  value?: string;
}

export interface Scope extends Range {
  scope: string;
}

export interface DateRange {
  minValue?: string;
  maxValue?: string;
  rangeType?: DateRangeType;
  dateType?: SliderType;
}

export enum DateRangeType {
  FIRST_TIME_PUBLICATIONS_UPDATES = 'FIRST_TIME_PUBLICATIONS_UPDATES',
  FIRST_TIME_PUBLICATIONS_ONLY = 'FIRST_TIME_PUBLICATIONS_ONLY',
  UPDATES_ONLY = 'UPDATES_ONLY',
}

export interface Stage {
  stage: string;
  currentStageOnly?: boolean;
}

export interface CategoryData {
  hideTooltips: boolean;
  isPublic: boolean;
}

export interface SubCategory {
  id: string;
  title: string;
  section?: string;
  component: Type<unknown>;
  data?: Partial<CategoryData>;
}

export interface ComponentFilters {
  id: number;
  title: string;
  section?: string;
  component: Type<unknown>;
  subCategories: SubCategory[];
}
