// @ts-nocheck
/* eslint-disable */
import { ggpIconAwardIcon } from './award';
import { ggpIconBuildingTypesIcon } from './building-types';
import { ggpIconCancelIcon } from './cancel';
import { ggpIconDesignIcon } from './design';
import { ggpIconErrataIcon } from './errata';
import { ggpIconExecutionIcon } from './execution';
import { ggpIconExpiryAlertIcon } from './expiry-alert';
import { ggpIconFinishedIcon } from './finished';
import { ggpIconIntentionIcon } from './intention';
import { ggpIconOpeningIcon } from './opening';
import { ggpIconPreparationIcon } from './preparation';
import { ggpIconPriorInformationIcon } from './prior-information';
import { ggpIconRestoreOutlineIcon } from './restore-outline';
import { ggpIconRestoreIcon } from './restore';
import { ggpIconTenderIcon } from './tender';
export const leadsIcons = [ggpIconAwardIcon, ggpIconBuildingTypesIcon, ggpIconCancelIcon, ggpIconDesignIcon, ggpIconErrataIcon, ggpIconExecutionIcon, ggpIconExpiryAlertIcon, ggpIconFinishedIcon, ggpIconIntentionIcon, ggpIconOpeningIcon, ggpIconPreparationIcon, ggpIconPriorInformationIcon, ggpIconRestoreOutlineIcon, ggpIconRestoreIcon, ggpIconTenderIcon];
