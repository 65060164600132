import { CheckboxItem } from '@ggp/generic/shared/util/models';

export enum LocationType {
  CONTINENTS = 'continents',
  COUNTRIES = 'countries',
  REGIONS = 'regions',
  PROVINCES = 'provinces',
  DEPARTMENTS = 'departments',
  ARRONDISSEMENTS = 'arrondissements',
}

export type Location = Record<LocationType, CheckboxItem[]>;
