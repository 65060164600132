import { map, Observable, shareReplay } from 'rxjs';
import { TreeFilter, TreeNode } from '@ggp/generic/shared/util/models';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EbpFilterListService {
  readonly #httpClient = inject(HttpClient);

  getFilterList(filterKey: TreeFilter): Observable<TreeNode[]> {
    const language = localStorage.getItem('currentLang') || 'en';
    return this.#httpClient.get<TreeNode[]>(`assets/filters/${filterKey}/${filterKey}-${language}.json`).pipe(
      map((data: TreeNode[]) => data),
      shareReplay(1),
    );
  }
}
