<div class="left-bar">
  <a
    *ngFor="let item of items$ | async; let i = index"
    routerLink="{{ item.redirection }}"
    ggpRouterLinkQueryIgnoreQuery="active"
    [queryParams]="item.queryParams"
    [attr.data-test-id]="item.data_test_id"
    [attr.data-cy]="item.data_test_id"
    (click)="clearFilters()"
  >
    <p *ngIf="item.title" class="subtitle-2">{{ item.title | translate }}</p>
    <svg-icon [key]="item.key"></svg-icon>
  </a>
</div>
