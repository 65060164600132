import * as mapboxgl from 'mapbox-gl';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@ggp/generic/shared/config/app';
import { ExecutionSite } from '@ggp/generic/shared/util/models';
import { Marker } from 'mapbox-gl';

type MapStyle = 'street';

@Component({
  selector: 'ggp-map',
  standalone: true,
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements AfterViewInit, OnDestroy {
  @Input() locations!: ExecutionSite[];
  @Input() iconsUrls!: string[];
  @Input() style!: MapStyle;
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef<HTMLDivElement>;

  #appConfig = inject<AppConfig>(APP_CONFIG);
  #host = inject(ElementRef);
  #dimensionsObserver = new ResizeObserver(() => {
    this.#map.resize();
  });
  #map!: mapboxgl.Map;

  ngAfterViewInit() {
    this.#initializeMap();
    this.#dimensionsObserver.observe(this.#host.nativeElement);

    this.#map.addControl(new mapboxgl.NavigationControl());

    this.#map.on('load', () => {
      this.locations.forEach(location => this.#createMarker(location));

      if (this.locations.length === 1) {
        this.#setSingleLocation(this.locations[0]);
        return;
      }

      const validLocations = this.locations.filter(this.#isValidLocation);
      if (validLocations.length) {
        const validLatLngs = validLocations.map(({ location }) => ({ lon: location.lng, lat: location.lat }));
        this.#fitMapToLocations(validLatLngs);
      }
    });
  }

  #initializeMap(): void {
    this.#destroyMapIfExist();

    this.#map = new mapboxgl.Map({
      accessToken: this.#appConfig.endpoints.mapbox.access_token,
      container: this.mapContainer.nativeElement,
      style: this.#appConfig.endpoints.mapbox.style[this.style],
      zoom: 13,
      attributionControl: false,
    });
  }

  #createMarker(location: ExecutionSite): Marker {
    const el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundColor = location.mapIcon.color;
    el.style.backgroundImage = `url(${location.mapIcon.url})`;
    el.style.backgroundSize = location.mapIcon.size;
    el.style.backgroundRepeat = 'no-repeat';
    el.style.backgroundPosition = 'center';
    el.style.position = 'absolute';
    el.classList.add(location.role === 'EXECUTION_SITE' ? 'custom-execution-style' : 'custom-authority-style');

    return new mapboxgl.Marker(el).setLngLat([location.location.lng, location.location.lat]).addTo(this.#map);
  }

  #setSingleLocation(location: ExecutionSite): void {
    this.#map.setCenter([location.location.lng, location.location.lat]);
  }

  #fitMapToLocations(coordinates: { lon: number; lat: number }[]): void {
    const bounds = coordinates.reduce((acc, coord) => acc.extend(coord), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
    this.#map.fitBounds(bounds, { padding: 64, maxZoom: 13 });
  }

  #isValidLocation(location: { location: { lng: number; lat: number } }): boolean {
    return typeof location.location.lng === 'number' && typeof location.location.lat === 'number';
  }

  #destroyMapIfExist(): void {
    if (this.#map) {
      this.#map.remove();
    }
  }

  ngOnDestroy() {
    this.#destroyMapIfExist();
    this.#dimensionsObserver.unobserve(this.#host.nativeElement);
  }
}
