import { Pipe, PipeTransform, inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterSelected } from '@ggp/generic/shared/services';
import { LocalizedDatePipe } from './localizedDate.pipe';
import { SliderType } from '@ggp/generic/shared/util/models';

@Pipe({
  name: 'dateRange',
  standalone: true,
})
@Injectable({ providedIn: 'root' })
export class DateRangePipe implements PipeTransform {
  readonly #translateService = inject(TranslateService);
  readonly #localizedDatePipe = inject(LocalizedDatePipe);

  transform(filter: FilterSelected): string {
    if (!filter?.displayText || !filter?.value?.minValue || !filter?.value?.maxValue) return '';

    const minValue = new Date(filter.value.minValue);
    const maxValue = new Date(filter.value.maxValue);
    let displayText = '';

    if (filter.value.dateType === SliderType.RANGE) {
      const filterName = this.#translateService.instant(filter.displayText.replace('_FILTER', ''));
      const fromTranslation = this.#translateService.instant('DATE_RANGE.FROM');
      const start = this.#getValue(minValue);
      const startText = this.#getDayName(minValue);
      const toTranslation = this.#translateService.instant('DATE_RANGE.TO');
      const end = this.#getValue(maxValue);
      const endText = this.#getDayName(maxValue);
      displayText = `${filterName}: ${fromTranslation} ${start} ${startText} ${toTranslation} ${end} ${endText}`;
    } else {
      const minDate = this.#localizedDatePipe.transform(minValue);
      const maxDate = this.#localizedDatePipe.transform(maxValue);
      displayText = this.#translateService.instant(filter.displayText, { minDate, maxDate });
    }

    const typeText = filter.value.type ? `(${this.#translateService.instant('DELIVERY.' + filter.value.type)})` : '';
    return `${displayText} ${typeText}`;
  }

  #getDayName(date: Date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);

    if (today.getTime() === date.getTime()) {
      return this.#translateService.instant('DATE_RANGE.TODAY');
    }

    return this.#translateService.instant(date < new Date() ? 'DATE_RANGE.AGO' : 'DATE_RANGE.IN_THE_FUTURE');
  }

  #getValue(targetDate: Date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const normalizedTargetDate = new Date(targetDate);
    normalizedTargetDate.setHours(0, 0, 0, 0);

    const dayDifference = Math.floor((normalizedTargetDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
    const numberOfYear = Math.floor(Math.abs(dayDifference) / 365);
    const numberOfMonths = this.#getMonths(today, normalizedTargetDate);

    if (numberOfYear > 0) {
      return `${numberOfYear} ${this.#translateService.instant(numberOfYear > 1 ? 'DATE_RANGE.YEARS' : 'DATE_RANGE.YEAR')}`;
    } else if (numberOfMonths > 0) {
      return `${Math.abs(numberOfMonths)} ${this.#translateService.instant(Math.abs(numberOfMonths) > 1 ? 'DATE_RANGE.MONTHS' : 'DATE_RANGE.MONTH')}`;
    } else {
      return dayDifference === 0
        ? ''
        : `${Math.abs(dayDifference)} ${this.#translateService.instant(Math.abs(dayDifference) > 1 ? 'DATE_RANGE.DAYS' : 'DATE_RANGE.DAY')}`;
    }
  }

  #getMonths(startDate: Date, endDate: Date) {
    const start = startDate.getTime() > endDate.getTime() ? endDate : startDate;
    const end = startDate.getTime() > endDate.getTime() ? startDate : endDate;
    const months = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth();

    if (end.getDate() < start.getDate()) {
      return months - 1;
    }

    return months;
  }
}
