import { Apollo } from 'apollo-angular';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG } from '@ggp/generic/shared/config/app';
import { SORT_FILTERS_TOKEN } from '@ggp/generic/shared/config/token';
import { DateRange, Filters, FilterTypes, LocationTypes, Range, Scope } from '@ggp/generic/shared/util/models';
import { LocaleLanguageService } from './locale-language.service';
import { Filter, FilterSelected } from './models';
import { getTotalItemsGQL } from './schemas/projectsTotalNumber.schema';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  readonly #apollo = inject(Apollo);
  readonly #httpClient = inject(HttpClient);
  readonly #router = inject(Router);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #sortConfig = inject(SORT_FILTERS_TOKEN, { optional: true });
  readonly showCurrentStageOnlyAppConfig = inject(APP_CONFIG).features.showCurrentStageOnly;
  readonly #localeLanguageService = inject(LocaleLanguageService);

  filtersSubject$ = new BehaviorSubject<Filter>({} as Filter);
  selectedProfileKeywords$ = new BehaviorSubject<string[]>([]);
  selectedProfileKeywordsObs$ = this.selectedProfileKeywords$.asObservable();
  #filter: Filter = {} as Filter;
  locationType = signal<LocationTypes>(LocationTypes.REGION);
  private readonly notificationsFiltersSubject$ = new BehaviorSubject<boolean>(false);

  set notificationsFiltersSubject(data: boolean) {
    this.notificationsFiltersSubject$.next(data);
  }

  get notificationsFiltersSubject(): Observable<boolean> {
    return this.notificationsFiltersSubject$.asObservable();
  }

  constructor() {
    this.#filter.filtersSelected = [];
  }

  addFilter(value: FilterSelected) {
    const existedFilter = this.#filter?.filtersSelected?.some(
      filterSelected => filterSelected.parentKey === value.parentKey && filterSelected.value === value.value,
    );

    if (!existedFilter && this.isAllowedToAdd(value.parentKey)) {
      this.#filter?.filtersSelected?.push(value);
      this.submitValues(this.#filter);
    }
  }

  private isAllowedToAdd(keyParent: FilterTypes) {
    return Object.values(FilterTypes).includes(keyParent);
  }

  removeFilter(filter: FilterSelected, shouldSubmitValues = true) {
    const pos = this.#filter?.filtersSelected?.findIndex(
      item => item.parentKey === filter.parentKey && JSON.stringify(item.value) === JSON.stringify(filter.value),
    );
    if (pos > -1) {
      this.#filter?.filtersSelected?.splice(pos, 1);
    }

    if (!this.#filter?.filtersSelected.some(({ parentKey }) => parentKey === FilterTypes.SubscriptionData)) {
      const demoFilterIndex = this.#filter?.filtersSelected.findIndex(({ parentKey }) => parentKey === FilterTypes.IsDemoProfile);
      demoFilterIndex > -1 && this.#filter?.filtersSelected?.splice(demoFilterIndex, 1);
    }

    shouldSubmitValues && this.submitValues(this.#filter);
  }

  removeAllByKeyParent(keyParent: FilterTypes) {
    const items = this.#filter?.filtersSelected?.filter(i => i.parentKey === keyParent);
    if (items) {
      items.forEach(item => {
        const pos = this.#filter?.filtersSelected?.findIndex(i => i.value === item.value);
        if (pos > -1) this.#filter?.filtersSelected?.splice(pos, 1);
      });
    }
    this.submitValues(this.#filter);
  }

  addListByKeyParent(list: FilterSelected[] | FilterSelected) {
    if (Array.isArray(list)) {
      list.forEach(item => {
        this.#filter.filtersSelected?.push(item);
      });
    } else {
      this.#filter.filtersSelected?.push(list);
    }

    this.submitValues(this.#filter);
  }

  updateAllByKeyParent(list: FilterSelected[], keyParent: FilterTypes) {
    if (!this.#filter.filtersSelected) {
      this.#filter.filtersSelected = [...list];
    } else {
      const newItemsMap = new Map(list.map(item => [item.value, item]));
      this.#filter.filtersSelected = this.#filter.filtersSelected
        .filter(item => item.parentKey !== keyParent || newItemsMap.has(item.value))
        .map(item => newItemsMap.get(item.value) ?? item);

      const remainingNewItems = list.filter(item => !this.#filter.filtersSelected.some(({ value }) => value === item.value));
      this.#filter.filtersSelected.push(...remainingNewItems);
    }

    this.submitValues(this.#filter);
  }

  clearAll() {
    this.#filter.filtersSelected = [];
    this.submitValues(this.#filter);
  }

  updateFilter(filter: FilterSelected) {
    const pos = this.#filter?.filtersSelected?.findIndex(item => item.parentKey === filter.parentKey);
    if (pos > -1) {
      this.#filter.filtersSelected[pos] = filter;
      this.submitValues(this.#filter);
    } else {
      this.addFilter(filter);
    }
  }

  editFilter(filters: FilterSelected[], value: boolean) {
    filters?.map(filter => {
      const isAnyFilterSelected = this.#filter?.filtersSelected?.some(item => item.parentKey === filter.parentKey && item.value === filter.value);
      filter.currentStageOnly = isAnyFilterSelected ? value : filter.currentStageOnly;
    });
    this.submitValues(this.#filter);
  }

  getTreeData(type = 'region', language = 'en') {
    const filtersUrl = `assets/filters/${type}/${type}-${language}.json`;

    return this.#httpClient.get(filtersUrl).pipe(
      map(data => {
        const filterData = data as any[];
        this.#sortFilters(filterData, type);

        return filterData;
      }),
    );
  }

  private submitValues(list: Filter) {
    this.filtersSubject$.next(list);
  }

  getCopyOfFilters() {
    return [...this.#filter.filtersSelected];
  }

  getTotalProjectsNumber({ projectType, favorite, deleted }: { projectType: string; favorite: boolean; deleted: boolean }) {
    const currentFilters = {
      ...this.getFiltersValues(),
      favorite,
      deleted,
    };

    return this.#apollo.query<{ getTotalItemsQuery: number }>({
      query: getTotalItemsGQL,
      variables: {
        projectType: projectType,
        filters: currentFilters,
        language: this.#localeLanguageService.getLocaleLanguage(),
      },
      fetchPolicy: 'no-cache',
    });
  }

  getTreeFilterValue(filtersSelection: FilterSelected[], filterName: string) {
    const filterIds: any = [];
    filtersSelection
      ?.filter(i => i.parentKey === filterName)
      .map(filter => {
        filterIds.push(filter.value);
        filter.children?.map(child => {
          if (!filterIds.includes(child)) {
            filterIds.push(child);
          }
        });
      });
    return filterIds;
  }

  getProfileValue(): number[] {
    const folder = this.#activatedRoute.snapshot.queryParamMap.get('folder');
    const subFolderId = this.#activatedRoute.snapshot.queryParamMap.get('subFolderId');
    const profilesFilter = this.#filter?.filtersSelected?.filter(({ parentKey }) => parentKey === 'subscription_data').map(({ value }) => +value);

    if (profilesFilter?.length) {
      return profilesFilter;
    }

    if (subFolderId && folder !== 'my_searches') {
      return [+subFolderId];
    }

    return [];
  }

  getIsDemoProfile(): boolean {
    const filtersSelection = this.#filter?.filtersSelected;
    const isDemoProfileFilter = filtersSelection?.filter(i => i.parentKey === 'isDemoProfile');
    const isDemoParam = this.#activatedRoute.snapshot.queryParamMap.get('isDemo') === 'true';

    return isDemoProfileFilter?.length === 1 ? isDemoProfileFilter[0].value : isDemoParam;
  }

  getFiltersValues(filter?: FilterSelected[]): Filters {
    const filtersSelection = filter ?? this.#filter?.filtersSelected;
    const readUnreadFilter = filtersSelection?.filter(i => i.parentKey === FilterTypes.ReadUnread);

    return {
      keywords: filtersSelection?.filter(i => i.parentKey === FilterTypes.Keyword).map(filter => filter.value as string),
      stages: filtersSelection
        ?.filter(i => i.parentKey === FilterTypes.ProcedureType)
        .map(filter => {
          return {
            stage: filter.value as string,
            currentStageOnly: filter.currentStageOnly !== undefined && this.showCurrentStageOnly(FilterTypes.ProcedureType) ? filter.currentStageOnly : false,
          };
        }),
      tags: filtersSelection?.filter(i => i.parentKey === FilterTypes.Tag).map(filter => filter.value as string),
      languages: filtersSelection?.filter(i => i.parentKey === FilterTypes.Language).map(filter => filter.value as string),
      regions: this.getTreeFilterValue(filtersSelection, FilterTypes.ExecutionSite),
      scopes: filtersSelection?.filter(i => i.parentKey === FilterTypes.Scope).map(filter => filter.value) as Scope[],
      activities: this.getTreeFilterValue(filtersSelection, FilterTypes.Activity),
      cpvs: this.getTreeFilterValue(filtersSelection, FilterTypes.Cpv),
      lastUpdateDate: filtersSelection?.filter(i => i.parentKey === FilterTypes.LastUpdate).map(filter => this.getDateOnly(filter) as DateRange)[0],
      startDate: filtersSelection?.filter(i => i.parentKey === FilterTypes.StartDate).map(filter => this.getDateOnly(filter) as DateRange)[0],
      deadlineDate: filtersSelection?.filter(i => i.parentKey === FilterTypes.Deadline).map(filter => this.getDateOnly(filter) as Range)[0],
      classes: this.getTreeFilterValue(filtersSelection, FilterTypes.Class),
      buildingTypes: this.getTreeFilterValue(filtersSelection, FilterTypes.BuildingType),
      projectTypes: filtersSelection?.filter(i => i.parentKey === FilterTypes.ProjectType).map(filter => filter.value as string),
      profiles: this.getProfileValue(),
      isDemoProfile: this.getIsDemoProfile(),
      ...(readUnreadFilter?.length === 1 ? { read: readUnreadFilter[0].value } : {}),
      isPerformancePlace: this.locationType() === LocationTypes.EXECUTION_SITE,
    };
  }

  showCurrentStageOnly(filterType?: string, projectType?: string): boolean {
    const projectTypeWithFallback = projectType ?? this.#router.routerState.snapshot.root.queryParams['projectType'];
    return filterType === FilterTypes.ProcedureType && projectTypeWithFallback === 'PUBLIC_TENDER' && !!this.showCurrentStageOnlyAppConfig;
  }

  #sortFilters(filterData: any[], type: string) {
    if (this.#sortConfig) {
      this.#sortConfig.forEach(filter => {
        if (filter.type === type) {
          const index = filterData.findIndex((item: any) => item.key === filter.key);

          if (index !== -1) {
            const [item] = filterData.splice(index, 1);
            filterData.splice(filter.position, 0, item);
          }
        }
      });
    }
  }

  getDateOnly(filter: FilterSelected): { [key: string]: number | string } {
    const { maxValue, minValue } = filter.value;

    return {
      ...filter.value,
      maxValue: maxValue ? this.getLocalDate(new Date(maxValue)).toISOString().split('T')[0] : null,
      minValue: minValue ? this.getLocalDate(new Date(minValue)).toISOString().split('T')[0] : null,
    };
  }

  private getLocalDate(date: Date): Date {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }

  private getRangeFilter(filtersSelection: FilterSelected[], key: string): Range {
    return filtersSelection?.filter(i => i.parentKey === key).map(filter => filter.value as Range)[0];
  }
}
