import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { DceDownloadResponse } from './models/interfaces/dceDownloadResponse.interface';
import { DceResponse } from './models/interfaces/dceResponse.interface';
import { DocsMethods, GetDocsPayload } from './models/interfaces/docsMethods.interface';

@Injectable({
  providedIn: 'root',
})
export class DocsService implements DocsMethods {
  getDocs(payload: GetDocsPayload): Observable<DceResponse> {
    throw new Error('Method not implemented.');
  }
  getFileContent(fileId: string, documentId: string): Observable<string> {
    throw new Error('Method not implemented.');
  }
  downloadFile(documentId: string, filesId: string[] | string): Observable<DceDownloadResponse> {
    throw new Error('Method not implemented.');
  }
}
