import { FilterTypes } from '@ggp/generic/shared/util/models';

export interface Filter {
  filtersSelected: FilterSelected[];
}

export interface FilterSelected {
  parentKey: FilterTypes;
  value: any;
  displayText?: any;
  title?: string;
  color?: string;
  children?: string[];
  currentStageOnly?: boolean;
}

export interface Range {
  minValue?: Date | number;
  maxValue?: Date | number;
  type?: string;
  optional?: boolean;
}

export interface DisplayTextDetails extends Range {
  label: string;
}

export enum ProjectSizeFilterKeys {
  SURFACE = 'scope-13',
  COST_ESTIMATE = 'scope-3221',
  BUILDINGS_NUMBER = 'scope-1267',
  FLOORS_NUMBER = 'scope-26',
  HOUSES_NUMBER = 'scope-31',
  DEMOLITION_VOLUME = 'scope-3260',
}
