import {
  AuthUser,
  confirmResetPassword,
  confirmSignIn,
  ConfirmSignInOutput,
  fetchAuthSession,
  fetchUserAttributes,
  resetPassword,
  signIn,
  SignInOutput,
  signOut,
  updatePassword
} from 'aws-amplify/auth';
import { BehaviorSubject, catchError, EMPTY, from, map, Observable, switchMap, take, tap, throwError } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BRAND_TOKEN } from '@ggp/generic/shared/config/token';
import { ProductsService, SubscriptionInvoiceStatus, ToasterService } from '@ggp/generic/shared/services';
import { TranslateService } from '@ngx-translate/core';

type SignInOutputWithEmail = SignInOutput & { email: string };

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly _cognitoUser$ = new BehaviorSubject<AuthUser | null>(null);
  private readonly _signInCognitoUser$ = new BehaviorSubject<SignInOutputWithEmail | null>(null);
  private readonly ToasterService = inject(ToasterService);
  private readonly translate = inject(TranslateService);
  readonly #deepLinkSubject$ = new BehaviorSubject<string>('');

  readonly #productService = inject(ProductsService);
  readonly #brandToken = inject(BRAND_TOKEN);
  readonly #router = inject(Router);

  get deepLink() {
    return this.#deepLinkSubject$.value;
  }

  set deepLink(url: string) {
    this.#deepLinkSubject$.next(url);
  }

  get signInCognitoUser(): SignInOutputWithEmail | null {
    return this._signInCognitoUser$.value;
  }

  set signInCognitoUser(value: SignInOutputWithEmail | null) {
    this._signInCognitoUser$.next(value)
  }

  get cognitoUser(): AuthUser | null {
    return this._cognitoUser$.value;
  }

  set cognitoUser(user: AuthUser | null) {
    this._cognitoUser$.next(user);
  }

  constructor() {
    this.checkSubscriptionStatus();
  }

  getCognitoGroups = (): Observable<string[]> =>
    this._amplifyAuthUserMiddleware(fetchAuthSession()).pipe(map(({ tokens }) => (tokens?.accessToken?.payload?.['cognito:groups'] as string[]) ?? []));

  confirmSignIn = (newPassword: string): Observable<ConfirmSignInOutput> => this._amplifyAuthUserMiddleware(confirmSignIn({ challengeResponse: newPassword }));

  signIn = (email: string, password: string): Observable<SignInOutput | null> =>
    this._amplifyAuthUserMiddleware(
      signIn({
        username: email,
        password,
        options: { authFlowType: 'USER_SRP_AUTH' },
      }),
    ).pipe(
      tap(data => this._signInCognitoUser$.next({ ...(data as SignInOutput), email }))
    );

  signOut = (): Observable<any> => this._amplifyAuthUserMiddleware(signOut());

  forgotPassword = (username: string): Observable<any> => this._amplifyAuthMiddleware(resetPassword({ username }));

  forgotPasswordConfirm = (username: string, code: string, password: string): Observable<void | null> =>
    this._amplifyAuthMiddleware<void>(
      confirmResetPassword({
        username,
        confirmationCode: code,
        newPassword: password,
      }),
    );

  updatePassword = (oldPassword: string, newPassword: string) =>
    this._amplifyAuthMiddleware<void>(
      updatePassword({
        oldPassword,
        newPassword,
      }),
    );

  private _amplifyAuthMiddleware = <T = any>(authFN: Promise<T>): Observable<T | null> => {
    return from(authFN).pipe(
      take(1),
      catchError(error => throwError(() => error)),
    );
  };

  private _amplifyAuthUserMiddleware = (authFN: Promise<AuthUser | any>): Observable<AuthUser | any> => {
    return this._amplifyAuthMiddleware<AuthUser | any>(authFN).pipe(
      map(user => (user ? user : null)),
      tap(user => (this.cognitoUser = user)),
      catchError(error => {
        if (error.message === 'Network error') {
          this.ToasterService.openToaster(this.translate.instant('ERRORS.SERVER.MESSAGE'), 'warning', this.translate.instant('ERRORS.SERVER.TITLE'));
          return EMPTY;
        }
        return throwError(() => error);
      }),
    );
  };

  getSubscriptionStatus(): Observable<{ invoice_status: SubscriptionInvoiceStatus }> {
    return from(fetchUserAttributes()).pipe(
      switchMap(() => this.#productService.getSubscriptionStatus()),
    );
  }

  checkSubscriptionStatus() {
    if (!this.#brandToken.isBackOffice) {
      setInterval(() => {
        this.getSubscriptionStatus().subscribe(response => {
          localStorage.setItem('subscriptionStatus', response.invoice_status);
          if (response.invoice_status === SubscriptionInvoiceStatus.BLOCKED_UNPAID) {
            this.#router.navigate(['/auth/unpaid']);
          }
        });
      }, 43200000);
    }
  }
}
