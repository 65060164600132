/* eslint-disable max-lines-per-function */
import { NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { fetchAuthSession } from 'aws-amplify/auth';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { ApolloLink, InMemoryCache, split } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { AppConfig } from '@ggp/generic/shared/config/app';

export function apolloGraphqlProvider(httpLink: HttpLink, environment: AppConfig): NamedOptions {

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.error(`[GraphQL Errors]:`, graphQLErrors);
    }
    if (networkError) {
      console.error(`[Network Error]:`, networkError);
    }
  });

  const basicContext = setContext(async (_, { headers }) => {
    const currentSession = await fetchAuthSession();
    const idToken = currentSession.tokens?.idToken;
    if (!idToken) {
      throw new Error('No valid ID token found');
    }

    return {
      headers: {
        ...headers,
        Accept: 'charset=utf-8',
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
    };
  });

  const httpLinkDefault = httpLink.create({ uri: environment.endpoints.app.uri });
  const httpLinkv2Url = httpLink.create({ uri: environment.endpoints.app.uriv2 });

  const wsLink = createSubscriptionHandshakeLink(
    {
      url: environment.endpoints.app.uri,
      region: 'eu-west-1',
      auth: {
        type: 'AMAZON_COGNITO_USER_POOLS' as const,
        jwtToken: async () => {
          const currentSession = await fetchAuthSession();
          return currentSession.tokens?.idToken?.toString() ?? '';
        },
      },
    },
    httpLinkDefault
  );

  const wsLinkBoapi = createSubscriptionHandshakeLink(
    {
      url:  environment.endpoints.app.uriv2 ? environment.endpoints.app.uriv2 : environment.endpoints.app.uri ,
      region: 'eu-west-1',
      auth: {
        type: 'AMAZON_COGNITO_USER_POOLS' as const,
        jwtToken: async () => {
          const currentSession = await fetchAuthSession();
          return currentSession.tokens?.idToken?.toString() ?? '';
        },
      },
    },
    httpLinkv2Url
  );

  const defaultSplitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink as unknown as ApolloLink,
    ApolloLink.from([basicContext, errorLink, httpLinkDefault])
  );

  const v2UrlSplitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLinkBoapi as unknown as ApolloLink,
    ApolloLink.from([basicContext, errorLink, httpLinkv2Url])
  );

  return {
    default: {
      link: defaultSplitLink,
      cache: new InMemoryCache(),
    },
    v2Url: {
      link: v2UrlSplitLink,
      cache: new InMemoryCache(),
    },
  };
}
