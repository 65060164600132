import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DetailViewActions {
  private eventSubject = new BehaviorSubject<string | null>(null);
  private expansionSubject = new BehaviorSubject<'collapse' | 'expand'>('collapse');

  event$ = this.eventSubject.asObservable();
  expansion$ = this.expansionSubject.asObservable();

  moveToProject(projectId: string) {
    this.eventSubject.next(projectId);
  }

  emitExpansionEvent(state: 'collapse' | 'expand') {
    this.expansionSubject.next(state);
  }
}
