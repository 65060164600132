import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Language } from '@ggp/generic/shared/util/models';

import { ExportedDataQuery } from './query/exportQuery';
import { onsaveResultGQL, startExecutionGQL } from './schemas/exportSchema';

@Injectable({
  providedIn: 'root',
})
export class EcrionService {
  private readonly apollo = inject(Apollo);

  startExecution(projectId: string, language: Language) {
    return this.apollo
      .query<ExportedDataQuery>({
        query: startExecutionGQL,
        variables: {
          projectId,
          language,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data?.startExecution;
        }),
      );
  }

  onsaveResult() {
    return this.apollo.subscribe<ExportedDataQuery>({
      query: onsaveResultGQL,
    });
  }
}
