import { SvgIcons } from '@ngneat/svg-icon';

export interface IStagesList {
  id: number;
  key: SvgIcons;
  title: string;
}

export const PUBLIC_STAGE_LIST: IStagesList[] = [
  {
    id: 0,
    key: 'prior-information',
    title: 'PRIOR',
  },
  {
    id: 1,
    key: 'tender',
    title: 'TENDER',
  },
  {
    id: 2,
    key: 'errata',
    title: 'ERRATA',
  },
  {
    id: 3,
    key: 'opening',
    title: 'OPENING',
  },
  {
    id: 4,
    key: 'award',
    title: 'AWARD',
  },
  {
    id: 5,
    key: 'expiry-alert',
    title: 'EXPIRYALERT',
  },
];

export const CONTRUCTION_STAGE_LIST: IStagesList[] = [
  {
    id: 0,
    key: 'intention',
    title: 'INTENTION',
  },
  {
    id: 1,
    key: 'design',
    title: 'DESIGN',
  },
  {
    id: 2,
    key: 'preparation',
    title: 'PREPARATION',
  },
  {
    id: 3,
    key: 'execution',
    title: 'EXECUTION',
  },
  {
    id: 4,
    key: 'finished',
    title: 'FINISHED',
  },
];

export const DISPLAYED_COLUMNS: string[] = ['select', 'stage', 'description', 'region', 'update', 'deadline', 'dce'];
