export enum FilterTypes {
  Activity = 'activities',
  BuildingType = 'building_types',
  Class = 'classes',
  Cpv = 'cpvs',
  Deadline = 'deadline',
  ExecutionSite = 'execution_site',
  Keyword = 'keyword',
  Language = 'language',
  LastUpdate = 'last_update',
  IsDemoProfile = 'isDemoProfile',
  ProcedureType = 'procedure_type',
  ProjectSize = 'project_size',
  ProjectType = 'project_type',
  ReadUnread = 'read_unread',
  Scope = 'scopes',
  StartDate = 'start_date',
  SubscriptionData = 'subscription_data',
  Tag = 'tags',
  Users = 'users',
}
