import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { ShareProject, ShareProjectResult } from '@ggp/generic/shared/util/models';
import { shareProjectMutationGQL } from '../schema/notification.schema';
import { ShareProjectMethods } from '@ggp/generic/shared/services';

@Injectable({
  providedIn: 'root',
})
export class EbpShareProjectService implements ShareProjectMethods {
  readonly #apollo = inject(Apollo);

  shareProject(shareProjectInput: ShareProject) {
    return this.#apollo
      .mutate<ShareProjectResult | null | undefined>({
        mutation: shareProjectMutationGQL,
        variables: {
          shareProjectInput,
        },
      })
      .pipe(
        map(({ data }) => data),
      );
  }
}
