<div class="container" *ngIf="projectLots$ | async as lots">
  <h6 *ngIf="lots.length > 0">{{ title | translate }}</h6>
  <ng-container *ngFor="let lot of lots">
    <div class="content">
      <span class="subtitle-2 lots">
        <span *ngIf="!showLotSubTitle(lot.lotNumber)">{{'PUBLIC.DETAIL.LOT' | translate }}</span> {{ lot.lotNumber }}:</span
      >
      <div class="title">
        <span class="subtitle-2"> {{lot.companyName}} </span>
      </div>
      <div class="amount" *ngIf="lot.amount">
        <span class="body-2"> {{lot.amount}} €</span>
      </div>
    </div>
    <mat-divider></mat-divider>
  </ng-container>
</div>
