import { Directive, ElementRef, inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { filter, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Directive({
  standalone: true,
  selector: '[ggpRouterLinkQueryIgnoreQuery]',
})
export class RouterLintActiveIgnoreQueryDirective implements OnInit, OnDestroy {
  @Input('ggpRouterLinkQueryIgnoreQuery') activeClass = 'active';
  @Input() routerLink = '';

  readonly #element = inject(ElementRef);
  readonly #renderer = inject(Renderer2);
  readonly #router = inject(Router);

  private subscription: Subscription = this.#router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => this.#updateActiveState());

  ngOnInit() {
    this.#updateActiveState();
  }

  #updateActiveState() {
    const currentUrlWithousParams = this.#router.url.split('?')[0];
    if (currentUrlWithousParams === this.routerLink) {
      this.#renderer.addClass(this.#element.nativeElement, this.activeClass);
    } else {
      this.#renderer.removeClass(this.#element.nativeElement, this.activeClass);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
