// @ts-nocheck
/* eslint-disable */
import { ggpIconClassesIcon } from './Classes';
import { ggpIconRootIcon } from './Root';
import { ggpIconTranslateIcon } from './Translate';
import { ggpIconUserMultipleIcon } from './User-multiple';
import { ggpIconBookIcon } from './book';
import { ggpIconCalendarIcon } from './calendar';
import { ggpIconCloseIcon } from './close';
import { ggpIconDownloadIcon } from './download';
import { ggpIconFilterIcon } from './filter';
import { ggpIconLinkToIcon } from './link-to';
import { ggpIconMinusCircleIcon } from './minus-circle';
import { ggpIconPlusCircleIcon } from './plus-circle';
import { ggpIconPlusIcon } from './plus';
import { ggpIconSettingsIcon } from './settings';
export const backOfficeIcons = [ggpIconClassesIcon, ggpIconRootIcon, ggpIconTranslateIcon, ggpIconUserMultipleIcon, ggpIconBookIcon, ggpIconCalendarIcon, ggpIconCloseIcon, ggpIconDownloadIcon, ggpIconFilterIcon, ggpIconLinkToIcon, ggpIconMinusCircleIcon, ggpIconPlusCircleIcon, ggpIconPlusIcon, ggpIconSettingsIcon];
