export enum TreeFilter {
  Activities = 'activities',
  BuildingType = 'building_type',
  Class = 'class',
  Cpv = 'cpv',
  ProjectType = 'project_type',
  Region = 'region',
}

export interface TreeNode {
  title: string;
  key: string;
  children: TreeNode[];
  checked?: boolean;
}

export interface FilteredTreeNodes {
  allTreeData: TreeNode[];
  filteredTreeData: TreeNode[];
}

export interface Lookup {
  value: string;
  label: string;
  is_selectable: boolean;
  selected: boolean;
  expanded: boolean;
}

export interface LookupTree {
  data: Lookup;
  children: LookupTree[];
  icon: string;
}

export interface EbpRegionLookup {
  value: string;
  label: string;
  is_selectable?: boolean;
  selected?: boolean;
  expanded?: boolean;
  display_order: number;
  sort_column: string;
}

export interface RegionLookupTree {
  data: EbpRegionLookup;
  children: RegionLookupTree[];
  icon?: string;
}

export interface FlatTreeNode {
  value: string;
  label: string;
  parent: string;
  has_children: boolean;
}
