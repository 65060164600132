import { HttpHandlerFn, HttpRequest } from '@angular/common/http';

export function OperationNameInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
  if (request.url?.includes('graphql') && request.body?.operationName) {
    const separator = request.url.includes('?') ? '&' : '?';
    const transformedRequest = request.clone({
      url: `${request.url}${separator}operation=${request.body.operationName}`,
    });
    return next(transformedRequest);
  }
  return next(request);
}
