import { Observable } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ProjectLots } from '@ggp/ebp/leads/util';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ggp-lots',
  standalone: true,
  templateUrl: './lots.component.html',
  styleUrls: ['./lots.component.scss'],
  imports: [CommonModule, MatDividerModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotsComponent {
  @Input() projectLots$!: Observable<ProjectLots[]>;
  @Input() title!: string;

  showLotSubTitle(lot: string) {
    return lot.toLowerCase().includes('lot');
  }
}
