import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { LinkComponent } from '@ggp/generic/shared/components/link';
import { DataLoadingDirective } from '@ggp/generic/shared/directives/data-loading';
import { SmallDirective } from '@ggp/generic/shared/directives/element/size';
import {
  DisplayPdf,
  DocsService,
  Document,
  File,
  FilterService,
  HighlighScroll,
  LocaleLanguageService,
  ProductsService,
  ToasterService,
} from '@ggp/generic/shared/services';
import { ProjectLink } from '@ggp/generic/shared/util/models';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AllDocumentsComponent } from './components/all-documents/all-documents.component';
import { PdfComponent } from './components/pdf/pdf.component';
import { WithKeywordsComponent } from './components/with-keywords/with-keywords.component';

interface DocsOverviewDetails {
  detailsPath: string;
  originalLinks: ProjectLink[];
  projectId: string;
  documentId: string;
}
@Component({
  selector: 'ggp-docs',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    MatButtonModule,
    TranslateModule,
    SmallDirective,
    MatTabsModule,
    AsyncPipe,
    PdfComponent,
    AllDocumentsComponent,
    WithKeywordsComponent,
    DataLoadingDirective,
    MatMenuModule,
    LinkComponent,
  ],
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayComponent implements OnInit, OnDestroy {
  readonly #dialogRef = inject(MatDialogRef);
  readonly #docsService = inject(DocsService);
  readonly #filterService = inject(FilterService);
  readonly #toasterService = inject(ToasterService);
  readonly #translate = inject(TranslateService);
  readonly #productsService = inject(ProductsService);
  readonly #onDestroy = new Subject();
  readonly docsOverviewDetails: DocsOverviewDetails = inject(MAT_DIALOG_DATA);
  readonly #localeLanguageService = inject(LocaleLanguageService);

  dataIsLoading = new BehaviorSubject<boolean | null>(false);
  fileIsLoading = new BehaviorSubject<boolean | null>(false);
  selectedPdf!: File | null;
  pdfPath? = '';
  allDocs!: Document;
  docsWithKeyWords: File[] = [];
  hasWithkeywordsProduct = signal<boolean | null>(null);
  selectedFileName!: string;
  highlightScroll: HighlighScroll | null = null;

  ngOnInit(): void {
    this.dataIsLoading.next(true);
    const keywords = this.getKeywordsByAvailabality();
    this.#docsService
      .getDocs({
        projectId: this.docsOverviewDetails?.projectId,
        documentId: this.docsOverviewDetails?.documentId,
        keywords,
        language: this.#localeLanguageService.getLocaleLanguage(),
      })
      .pipe(takeUntil(this.#onDestroy))
      .subscribe({
        next: data => {
          this.dataIsLoading.next(false);
          this.allDocs = data.allDocs;
          if (data.withKeywords && data.withKeywords.length > 0) {
            this.docsWithKeyWords = data.withKeywords;
          }
        },
        error: error => {
          this.dataIsLoading.next(false);
          this.#toasterService.openToaster(this.#translate.instant('ERRORS.SERVER.MESSAGE'), 'warning', this.#translate.instant('ERRORS.SERVER.TITLE'));
        },
      });
    this.#productsService.getValue().subscribe(hasProduct => {
      if (!hasProduct) {
        this.highlightScroll = null;
        this.selectedPdf = null;
        this.hasWithkeywordsProduct.set(false);
      } else {
        this.hasWithkeywordsProduct.set(true);
      }
    });
  }

  private getKeywordsByAvailabality(): string[] {
    const keywords = this.#filterService.getFiltersValues().keywords || [];
    const mergedSet = new Set([...this.#filterService.selectedProfileKeywords$.value, ...keywords]);
    return Array.from(mergedSet);
  }

  setSelectedTab(event: MatTabChangeEvent) {
    this.highlightScroll = null;
    this.selectedPdf = null;
    this.#productsService.stagesProductsSubject$.pipe(takeUntil(this.#onDestroy)).subscribe(data => {
      if (data.dce.includes('dce-keywords') || event.index === 0) this.hasWithkeywordsProduct.set(true);
      else if (this.docsWithKeyWords?.length === 0 || !data.dce.includes('dce-keywords')) this.hasWithkeywordsProduct.set(false);
    });
  }

  close(shouldOpenProjectDetails = false): void {
    this.#dialogRef.close(shouldOpenProjectDetails);
  }

  displayPDF(displayPdf: DisplayPdf) {
    if (displayPdf && displayPdf.file !== this.selectedPdf) {
      this.selectedPdf = displayPdf.file;
      if (this.selectedPdf.id && this.selectedPdf.preview) {
        this.fileIsLoading.next(true);
        this.#docsService
          .getFileContent(displayPdf?.file.id, displayPdf?.documentId)
          .pipe(takeUntil(this.#onDestroy))
          .subscribe(data => {
            this.fileIsLoading.next(false);
            this.selectedFileName = displayPdf.file.name;
            this.pdfPath = data;
          });
      } else {
        this.pdfPath = undefined;
      }
    }
  }

  openProjectDetails() {
    this.close(true);
  }

  getSearchKeywords() {
    return this.#filterService.getFiltersValues().keywords ?? [];
  }

  pdfScrollTo(searchSpan: HighlighScroll) {
    this.highlightScroll = searchSpan;
  }

  ngOnDestroy(): void {
    this.#onDestroy.next(null);
    this.#onDestroy.complete();
  }
}
