import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCost',
  standalone: true,
})
export class FormatCostPipe implements PipeTransform {
  transform(minValue: number | string | null, maxValue: number | string | null): string {
    const formattedMinValue = this.#formatCost(minValue);
    const formattedMaxValue = this.#formatCost(maxValue);

    if (formattedMinValue && formattedMaxValue) {
      return `${formattedMinValue} - ${formattedMaxValue}`;
    } else if (formattedMinValue) {
      return formattedMinValue;
    } else if (formattedMaxValue) {
      return formattedMaxValue;
    }

    return '';
  }

  #formatCost = (value: number | string | null): string => {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return '';
    }

    const numValue = Number(value);
    if (numValue >= 1e9) {
      return '€' + (numValue / 1e9).toFixed(2) + 'B';
    } else if (numValue >= 1e6) {
      return '€' + (numValue / 1e6).toFixed(2) + 'M';
    } else if (numValue >= 1e3) {
      return '€' + (numValue / 1e3).toFixed(2) + 'K';
    } else {
      return '€' + numValue.toFixed(2);
    }
  };
}
