import { Apollo } from 'apollo-angular';
import { BehaviorSubject, map } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { PRODUCT_TYPES_TOKEN } from '@ggp/generic/shared/config/token';
import { DCE_PRODUCTS, Language, PRODUCTS, ProductsTypes } from '@ggp/generic/shared/util/models';

import { ProductData, UserProducts } from './models';
import { ProductsQuery } from './query/productsQuery';
import { SubscriptionQuery } from './query/subscriptionQuery';
import { getPriorSourceQueryGQL, getProductsQueryGQL, getSubscriptionPageQueryGQL } from './schemas/products.schema';
import { getSubscriptionStatusQueryGQL } from './schemas/subscription.schema';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  readonly #apollo = inject(Apollo);
  stagesProductsSubject$ = new BehaviorSubject<UserProducts>({ projects: [], stages: [], dce: [] });
  notifyUpgradeSubscription: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  notifyUpgradeSubscription$ = this.notifyUpgradeSubscription.asObservable();
  private subject = new BehaviorSubject<boolean>(true);
  readonly #productsSubject$ = new BehaviorSubject<ProductData>({
    data: [],
    stages: [],
    projects: [],
    dce: [],
  });
  products$ = this.#productsSubject$.asObservable();

  get products() {
    return this.#productsSubject$.value;
  }

  set products(products: ProductData) {
    this.#productsSubject$.next(products);
  }

  readonly stagesProductEnum = inject(PRODUCT_TYPES_TOKEN);

  emitValue(newValue: boolean) {
    this.subject.next(newValue);
  }

  getValue() {
    return this.subject.asObservable();
  }

  setNotification(value: boolean) {
    this.notifyUpgradeSubscription.next(value);
  }

  getProducts() {
    return this.#apollo
      .query<ProductsQuery>({
        query: getProductsQueryGQL,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          this.products = { data: data.getProductsQuery ?? [] } as ProductData;
          return {
            data: data?.getProductsQuery,
            stages: Object.values(this.stagesProductEnum),
            projects: PRODUCTS,
            dce: DCE_PRODUCTS,
          };
        }),
      );
  }

  getSubscriptionPage(scope: string, locale: Language, pageType: ProductsTypes) {
    return this.#apollo
      .query<ProductsQuery>({
        query: getSubscriptionPageQueryGQL,
        variables: {
          scope,
          locale,
          pageType,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data?.getSubscriptionPageQuery;
        }),
      );
  }

  getPriorSourceQuery(projectId: string) {
    return this.#apollo
      .query<ProductsQuery>({
        query: getPriorSourceQueryGQL,
        variables: {
          projectId,
        },
      })
      .pipe(
        map(({ data }) => {
          return data?.getPriorSourceQuery;
        }),
      );
  }

  getSubscriptionStatus() {
    return this.#apollo
      .query<SubscriptionQuery>({
        query: getSubscriptionStatusQueryGQL,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map(({ data }) => {
          return data?.getSubscriptionStatusQuery;
        }),
      );
  }
}
