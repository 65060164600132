import { gql } from '@apollo/client';

export const getSubscriptionStatusQueryGQL = gql`
  query getSubscriptionStatusQuery {
    getSubscriptionStatusQuery {
      id
      is_active
      invoice_status
    }
  }
`;
